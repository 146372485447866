// 初始时间格式：2019-08-20T22:54:46.342+00:00
// 格式化时间后：2019年8月20日 22时54分46秒

export default function timeFormat(dateString) {
    dateString = dateString.replace('-', '年', 1)
    dateString = dateString.replace('-', '月')
    dateString = dateString.replace('T', '日 ')
    dateString = dateString.replace(':', '时')
    dateString = dateString.replace(':', '分')
    dateString = dateString.replace(/\.\d*\+\d*:\d*/g, '秒') 
    return dateString
}