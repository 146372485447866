import React, { useState, useEffect } from "react";
import { getUserFuncState, addUserFunc, getSubjectList, changeSubjectState } from '../../../api/request'
import { Button, message } from 'antd'
import cookie from 'react-cookies'

import './leetcoderecord.css'

export function LeetcodeRecord(props) {

    const {
    } = props;

    const [lcRecordState, setLcRecordState] = useState(false);
    const [subjectList, setSubjectList] = useState('');
    const [userId, setUserId] = useState('');


    // 获取用户信息
    function getUserMessage() {
        let userMessage = cookie.load('userMessage');
        if (userMessage != null) {
            setUserId(userMessage.id)
            return userMessage.id;
        }
        return null;
    }

    // 初始化题目列表 
    async function SubjectsInit(userid) {
        const result = await getSubjectList(userid);
        let subjectListItem = {};
        // console.log(result);
        result.map(item => {
            if (subjectListItem[item['firstTag']] == undefined) {
                subjectListItem[item['firstTag']] = {};
            }
            if (subjectListItem[item['firstTag']][item['secondTag']] == undefined) {
                subjectListItem[item['firstTag']][item['secondTag']] = [];
            }
            subjectListItem[item['firstTag']][item['secondTag']].push(item);
        })
        // console.log(subjectListItem);
        setSubjectList(subjectListItem);
    }

    function ChangeSubjectState(subjectItem) {
        let tempSubject = Object.assign({}, subjectList);
        let secondTagList = tempSubject[subjectItem['firstTag']][subjectItem['secondTag']];
        secondTagList.map(item => {
            if (item['subjectNum'] == subjectItem['subjectNum']) {
                if (item['state'] == 2) {
                    item['state'] = 1;
                } else {
                    item['state'] += 1;
                }
                // 调用后端接口改变subject状态
                changeSubjectState({ userid: userId, subjectid: item['id'], state: item['state'] });
            }
        })
        tempSubject[subjectItem['firstTag']][subjectItem['secondTag']] = secondTagList;
        // console.log(tempSubject,"tempSubject");
        setSubjectList(tempSubject);
    }

    async function activeFunc(){
        let userId = getUserMessage();
        if(userId == null){
            message.warning("未登录，请登录后激活");
        }else{
            const result = await addUserFunc({userid: userId, funcname: 'leetcodeRecord'});
            console.log(result);
            if(result == 1){
                message.success("激活成功");
                setLcRecordState(true);
                SubjectsInit(userId);
            }else{
                message.error("发生未知错误，激活失败");
            }
        }
    }

    useEffect(() => {
        const componentInit = async () => {
            let id = getUserMessage();
            if (id != null) {
                // 获取用户该功能的开启状态
                const result = await getUserFuncState({ userid: id, funcname: 'leetcodeRecord' });
                if (result.state == '1') {
                    setLcRecordState(true);
                    SubjectsInit(id);
                }
            }
        }
        componentInit();
    }, [])


    return (
        <div className='lcrecord-root'>
            {
                lcRecordState == false ?
                    <div>
                        <div className='lcrecordno-head'>
                            <h1>提示</h1>
                            <h4>使用前请详细阅读本提示：工具提供标记题目完成状态的功能，通过点击改变题目的完成状态，分为未完成、已完成、不会做三种状态且点击后仅可以在已完成和不会做两种状态之间切换。
                                题目顺序来自网络(原文网址：<a target='blank' href='https://www.jianshu.com/p/460edbe3dc36'>https://www.jianshu.com/p/460edbe3dc36</a>)，类型包括数组、栈、二叉树、动规等。</h4>
                            <Button onClick={() => activeFunc()} style={{backgroundColor: '#6bc30d', color: '#fff'}}>激活并使用</Button>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='lcrecord-head'>
                            <div className='lcrecord-subject-demo'>
                                未开始
                            </div>
                            <div className='lcrecord-subjectNum-state1 lcrecord-subject-demo'>
                                已完成
                            </div>
                            <div className='lcrecord-subjectNum-state2 lcrecord-subject-demo'>
                                不会做
                            </div>
                        </div>
                        <div className='lcrecord-body'>
                            {
                                Object.keys(subjectList).map(firstTag => {
                                    let firstObj = subjectList[firstTag];
                                    return (<div key={firstTag}>
                                        <h3>{firstTag}</h3>
                                        {
                                            Object.keys(firstObj).map(secondTag => {
                                                let secondObj = firstObj[secondTag];
                                                return (<div className='lcrecord-secondTag' key={secondTag}>
                                                    <h4>{secondTag}</h4>
                                                    <div className='lcrecord-secondTag-right'>
                                                        {secondObj.map(subjectItem => {
                                                            return (<div key={subjectItem['subjectNum']} className={"lcrecord-subjectNum lcrecord-subjectNum-state" + subjectItem['state']} onClick={() => ChangeSubjectState(subjectItem)} title='456456456'>{subjectItem.subjectNum}</div>)
                                                        })}
                                                    </div>
                                                </div>)
                                            })
                                        }
                                        <hr color='#F2F4F5'></hr>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
            }
        </div>
    );

}

