/**
 * 网络请求配置
 */
import axios from "axios";
import qs from 'qs';

axios.defaults.timeout = 100000;
axios.defaults.baseURL = "https://www.jhxblog.cn/";
// axios.defaults.baseURL = "http://127.0.0.1:8001/";
axios.defaults.withCredentials = true; // 与后端交互时默认携带cookie  

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    // config.data = JSON.stringify(config.data);
    // config.headers = {
    //   "Content-Type": "application/json",
    // };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    if (response.data.errCode === 2) {
      console.log("过期");
    }
    return response;
  },
  (error) => {
    console.log("请求出错：", error);
    msag(error);
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: params,
      }).then((response) => {
        // landing(url, params, response.data);
        // if(response)
        // console.log('res',response)
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
//  qs.stringify(data)
export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        resolve(response.data);
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}

//统一接口处理，返回数据
export default function myajax(url, param, fecth='GET') {
  let _data = "";
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case "GET":
        console.log("begin a get request,and url:", url);
        get(url, param)
          .then((response) => {
            // console.log("response:" + response);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log("***********************");
            if (error.response) {
              // 请求已发出，但服务器响应的状态码不在 2xx 范围内
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
            console.log("***********************");
          });
        
          // .catch((error) => {
          //   console.log("get request GET failed.", error.response);
          //   console.log(Object.keys(error));
          //   // msag(error);
          //   reject(0); 
          //   // return 0;
          // });
        break;
      case "POST":
        post(url, param)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("get request POST failed.", error);
            reject(error);
            // msag(error);
            // return 0;
          });
        break;
      default:
        break;
    }
  });
}

//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        alert("未授权，请登录");
        break;

      case 403:
        alert("拒绝访问");
        break;

      case 404:
        alert("请求地址出错");
        break;

      case 408:
        alert("请求超时");
        break;

      case 500:
        alert("服务器内部错误");
        break;

      case 501:
        alert("服务未实现");
        break;

      case 502:
        alert("网关错误");
        break;

      case 503:
        alert("服务不可用");
        break;

      case 504:
        alert("网关超时");
        break;

      case 505:
        alert("HTTP版本不受支持");
        break;
      default:
    }
  }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
  if (data.code === -1) {
  }
}

