import React from 'react'

class categoryManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div></div>
        );
    }
}

export default categoryManage;