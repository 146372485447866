import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Popover } from 'antd'
import { GithubOutlined, QqOutlined, WechatOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons'
import cookie from 'react-cookies'

import { baseURL } from '../../api/config'
import { confirmUser } from '../../api/request'
import './home-footer.css'

const content = (
    <div className='wechat-img'>
      <img src={ baseURL + 'image/baseimage/wechat.jpg'} style={{height: '100px', width: '100px'}}></img>
    </div>
);

class HomeFooter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            manageFlag: false,
            settingFlag: false
        }
    }

    componentDidMount() {
       this.tConfirmUser();
    }

    tConfirmUser = async() => {
        this.userMessage = cookie.load('userMessage');
        if(this.userMessage != null){
            const pid = this.userMessage.openId;
            console.log(this.userMessage);
            const result = await confirmUser(pid);
            if(result == 1){
                this.setState({settingFlag: true})
            }
        }
    }
    

    render() {
        const path = this.props.location.pathname;
        const { settingFlag } = this.state;
        return (
            <div className='home-footer-root'>
                <div className='home-footer-link'>
                        <a href="https://github.com/redstarbrother" className="github" target="_blank"><GithubOutlined style={{ color: '#08c' }} /></a>
                        <a href="https://wpa.qq.com/msgrd?v=3&amp;uin=243340067&amp;site=qq&amp;menu=yes" className="qq" target="_blank" title="243340067"><QqOutlined /></a>
                        <a href="https://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&amp;email=EyEnICAnIyMlJFNiYj1wfH4" className="email" target="_blank" title="243340067@qq.com"><MailOutlined style={{ color: '#F4FA58'}} /></a>
                        <Popover content={content} title="扫一扫，添加微信好友">
                            <a href="javascript:void(0)" className="weixin"><WechatOutlined style={{ color: '#FE2E2E'}} /></a>
                        </Popover>
                        {
                            path == '/home/about' && settingFlag == true ?<Link to='/bgcontrol' className='setting' ><SettingOutlined /></Link>:''
                        }
                    </div>
                    <div className='home-footer-sitemessage'>
                        <p>Copyright © 2019 - 豫ICP备19005002号, All Rights Reserved Powered By 举个锤子 3.0.0BETA</p>
                </div>
            </div>
        )
    }
}

export default withRouter(HomeFooter);
