import React from 'react'
import { withRouter } from 'react-router-dom'
import './bg-head.css'

const menuList = [
    {
        key: '/bgcontrol',
        val: '主页'
    },
    { 
        key: '/bgcontrol/home',
        val: '主页'
    },
    {
        key: '/bgcontrol/basesetting',
        val: '基本设置'
    },
    {
        key: '/bgcontrol/articlelist',
        val: '文章列表'
    },
    {
        key: '/bgcontrol/addarticle',
        val: '添加文章'
    },
    {
        key: '/bgcontrol/categorymanage',
        val: '分类管理'
    },
    {
        key: '/bgcontrol/commentmanage',
        val: '评论管理'
    },
    {
        key: '/bgcontrol/messagemanage',
        val: '留言板管理'
    },
    {
        key: '/bgcontrol/statemanage',
        val: '动态管理'
    },
    {
        key: '/bgcontrol/updatamanage',
        val: '更新日志管理'
    },
    {
        key: '/bgcontrol/linkmanage',
        val: '友情链接管理'
    },
    {
        key: '/bgcontrol/articlemanage',
        val: '文章列表'
    },
    {
        key: '/bgcontrol/addarticle',
        val: '添加文章'
    },
    
]

class BgHead extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }
    
    render() {
        var title = ''
        const path = this.props.location.pathname   // 获取当前url
        menuList.forEach(element => {      // 根据url匹配title值
            if(element.key === path){
                title = element.val
            }
        });
        
        return (
            <div className='control-title'>
                <div className='title' style={{paddingTop: '1%', paddingLeft: '5%'}}>
                    <h3 style={{fontWeight:'bolder'}}>{title}</h3>
                </div>
                
            </div>
        );
    }
}

// export default BgHead;
export default withRouter(BgHead);