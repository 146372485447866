import React from 'react'
import { Table, Button, Modal, message, Tooltip, Space, Input } from 'antd'

import timeFormat from '../../../api/timeFormat';
import { bgAddUpdata, bgDeleteUpdata, bgGetUpdataList } from '../../../api/request';

const { TextArea } = Input

class UpdataManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            updataList: [],
            tableLoading: true,
            visible: false,
            updataNid: null,
            updataContent: ''
         };
    }

    componentDidMount() {
        this.initColumns()
        this.getUpdataList()
    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '更新日志ID',
                dataIndex: 'nid',
                key: 'nid',
                width: 10
            }, 
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            { 
                title: '更新日志内容', 
                dataIndex: 'detail',
                key:"detail",
                width: 40,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            },  
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                    <Space size="middle">
                    <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                    </Space>
                ),
                },
        ];
        
    }

    // 获取更新日志数据列表
    getUpdataList = async () => {
        const result = await bgGetUpdataList()
        if(result){
            result.forEach(element => element['createTime'] = timeFormat(element['createTime']))
            const updataList = result;
            // console.log(updataList);
            this.setState({
                updataList: updataList,
                tableLoading: false
            })
        }else{
            message.error('获取动态列表失败！')
        }
    }

    // 改变对话框状态
    showDelete = (e) => {
        this.setState({
            updataNid: e.nid,
            visible: true
        })
    }

    // 取消删除动作
    hideDelete = () => {
        this.setState({
            updataNid: null,
            visible: false
        })
    }

    // 删除更新日志数据
    deleteUpdata = async () => {
        if(this.state.updataNid == null){
            message.error('操作错误！')
            this.setState({
                visible: false
            })
        }
        const result = await bgDeleteUpdata(this.state.updataNid)
        if(result){
            let datalog = this.state.updataList
            datalog = datalog.filter(item => item.nid !== this.state.updataNid)
            message.success('删除成功!')
            this.setState({
                updataList: datalog,
                visible: false
            })
            
        }else{
            message.error('动态删除失败！')
        }
    }

    // 获取新增动态的内容
    contentChange = (e) => {
        const constent = e.target.value
        this.setState({
            updataContent: constent
        })
    }

    // 添加更新日志
    addUpdata = async() => {
        // console.log(this.state.updataContent);
        let { updataContent } = this.state
        // console.log(updataContent);
        if(updataContent == ''){
            message.warning("提交内容不能为空！")
            return;
        }
        const result = await bgAddUpdata(updataContent)
        this.getUpdataList()
        // console.log('result:' , result);
        if(result){
            message.success('添加成功！')
            this.setState({
                updataContent: ''
            })
        }else{
            message.error('添加失败！')
        }

    }


    render() {
        return (
            <div>
                <div className='addUpdata'>
                    {/* <EmojiArea /> */}
                    <TextArea showCount maxLength={100} rows='4' value={this.state.updataContent} onChange = { this.contentChange } />
                    <Button onClick={this.addUpdata}>添加动态</Button>
                </div>
                <Table
                    columns = {this.columns}
                    dataSource = {this.state.updataList}
                    rowKey = 'nid'
                    loading = {this.state.tableLoading}
                />
                <Modal
                    visible = {this.state.visible}
                    title = '删除'
                    icon = 'ExclamationCircleOutlined'
                    okText = '删他娘的'
                    cancelText = '取消'
                    onOk = {this.deleteUpdata}
                    onCancel = { this.hideDelete }

                >
                    <p>确定要删除这条更新日志吗？</p>
                </Modal>
            </div>
        );
    }
}

export default UpdataManage;