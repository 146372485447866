import './ajax'
import ajax from './ajax'
import myajax from './myajax'


const UploadArticleImageUrl = 'xbmanage/articlemanage/addimg' 
const GetUserMessageUrl = 'login/getusermessage'
const QqLoginUrl = 'login/qqlogin'
const AddCommentUrl = 'read/addcomment'
const GetCommentListUrl = 'read/getcommentlist'
const GetExtendArticleUrl = 'read/getextendarticle'
const GetArticleUrl = 'read/getarticle'
const GetUpdataListUrl = 'about/getupdatalist'
const AddMessageUrl = 'message/addmessage'
const GetMessagePageNumUrl = 'message/getmessagepagenum'
const GetMessageListUrl = 'message/getmessagelist'
const GetStateListUrl = 'state/getstatelist'
const GetLinkListUrl = 'article/getlinklist'
const GetHotArticleListUrl = 'article/gethotarticlelist'
const GetArticleListUrl = 'article/getarticlelist'
const GetCategoryListUrl = 'article/getcategorylist'
const GetPaginationUrl = 'article/getpagination'
const BgGetCommentListUrl = 'xbmanage/commentmanage/getcommentlist'
const BgDeleteCommentUrl = 'xbmanage/commentmanage/deletecomment'
const BgGetMessageListUrl = 'xbmanage/messagemanage/getmessagelist'
const BgDeleteMessageUrl = 'xbmanage/messagemanage/deletemessage'
const BgGetStateListUrl = 'xbmanage/statemanage/getstatelist'
const BgDeleteStateUrl = 'xbmanage/statemanage/deletestate'
const BgAddStateUrl = 'xbmanage/statemanage/addstate'
const BgGetUpdataListUrl = 'xbmanage/updatamanage/getupdatalist'
const BgAddUpdataUrl = 'xbmanage/updatamanage/addupdata'
const BgDeleteUpdataUrl = 'xbmanage/updatamanage/deleteupdata'
const BgGetLinkListUrl = 'xbmanage/linkmanage/getlinklist'
const BgDeleteLinkUrl = 'xbmanage/linkmanage/deletelink'
const BgAddLinkUrl ='xbmanage/linkmanage/addlink'
const BgGetArticleListUrl = 'xbmanage/articlemanage/getarticlelist'
const BgDeleteArticleUrl = 'xbmanage/articlemanage/deletearticle'
const BgAddArticleUrl = 'xbmanage/articlemanage/addarticle'
const BgGetCategoryListUrl = 'xbmanage/categorymanage/getcategorylist'
const BgURTodoListUrl = '/xbmanage/todolist/geturtodolist'
const BgRDTodoListUrl = '/xbmanage/todolist/rdtodolist'
const ConfirmUserUrl = './user/confirmuser'
const GetUserFuncStateUrl = 'labor/getuserfuncstate'
const AddUserFuncUrl = 'labor/adduserfunc'
const GetSubjectListUrl = 'lablcrecord/getrecordlist'
const ChangeSubjectStateUrl = 'lablcrecord/changesubjecrstate'
const ChangeXbClockOptionsUrl = 'labxbclock/changeoptions'
const getXbClockIdUrl = 'labxbclock/getclockid'

const Bgstatus = 'bgmanage/status'


// 获取用户信息
// export const bgAddArticle = (formData) => myajax(BgAddArticleUrl, formData, 'POST')
export const UploadArticleImage = (formData) => myajax(UploadArticleImageUrl, formData, 'POST');

// 获取用户信息
export const getUserMessage = (userOpenId) => myajax(GetUserMessageUrl, {userOpenId});

// 用户登录
export const qqLogin = (localUrl) => myajax(QqLoginUrl, {localUrl}); 

// 文章阅读页面获取评论数据
export const addComment = (articleId, openId, remarkCommentId, content) => myajax(AddCommentUrl, {articleId, openId, remarkCommentId, content}, 'POST'); 

// 文章阅读页面获取评论数据
export const getCommentList = (articleId) => myajax(GetCommentListUrl, {articleId}); 

// 文章阅读页面获取扩展文章数据
export const getExtendArticle = (articleId) => myajax(GetExtendArticleUrl, {articleId}); 

// 文章阅读页面获取文章数据
export const getArticle = (articleId) => myajax(GetArticleUrl, {articleId}); 

// 关于页面获取更新日志列表
export const getUpdataList = () => myajax(GetUpdataListUrl, {}); 

// 留言板页面新增留言
export const addMessage = (openId, remarkMessageId, content) => myajax(AddMessageUrl, {openId, remarkMessageId, content}, 'POST'); 

// 留言板页面获取留言总页数
export const getMessagePageNum = () => myajax(GetMessagePageNumUrl, {}); 

// 留言板页面获取留言列表
export const getMessageList = (page) => myajax(GetMessageListUrl, {page}); 

// 动态页面获取动态列表
export const getStateList = () => myajax(GetStateListUrl, {}); 

// 文章页面获取友情链接列表
export const getLinkList = () => myajax(GetLinkListUrl, {}); 

// 文章页面获取文章列表
export const getHotArticleList = (articleNum) => myajax(GetHotArticleListUrl, {articleNum}); 

// 文章页面获取文章列表
export const getArticleList = (categoryId, pageNum, pageSize) => myajax(GetArticleListUrl, {categoryId, pageNum, pageSize}); 

// 文章页面获取文章分类数据
export const getCategoryList = () => myajax(GetCategoryListUrl, {});

// 文章页面获取文章分页数据
export const getPagination = (categoryId) => myajax(GetPaginationUrl, {categoryId});

// BG获取管理系统commentManage页面评论数据
export const bgGetCommentList = () => myajax(BgGetCommentListUrl, {});

// BG根据nid删除评论
export const bgDeleteComment = nid => myajax(BgDeleteCommentUrl, {nid});

// BG获取管理系统messageManage页面留言数据
export const bgGetManageList = () => myajax(BgGetMessageListUrl, {})

// BG根据nid删除留言
export const bgDeleteMessage = nid => myajax(BgDeleteMessageUrl, {nid});

// BG获取管理系统stateManage页面动态数据
export const bgGetStateList = () => myajax(BgGetStateListUrl, {});

// BG根据nid删除动态
export const bgDeleteState = nid => myajax(BgDeleteStateUrl, {nid});

// BG插入state数据
export const bgAddState = detail => myajax(BgAddStateUrl, {detail}, 'POST');

// BG获取管理系统updataManage页面更新日志数据
export const bgGetUpdataList = () => myajax(BgGetUpdataListUrl, {});

// BG根据nid删除更新日志数据
export const bgDeleteUpdata = nid => myajax(BgDeleteUpdataUrl, {nid});

// BG添加更新日志数据
export const bgAddUpdata = detail => myajax(BgAddUpdataUrl, {detail}, 'POST');

// BG获取管理系统linkManage页面友情链接数据
export const bgGetLinkList = () => myajax(BgGetLinkListUrl, {});

// BG根据nid删除友情链接
export const bgDeleteLink = nid => myajax(BgDeleteLinkUrl, {nid});

// BG添加友情链接
export const bgAddLink = ({webName, webLink, contact}) => myajax(BgAddLinkUrl, {webName, webLink, contact}, 'POST');

// BG获取管理系统articleManage页面文章列表数据
export const bgGetArticleList = () => myajax(BgGetArticleListUrl, {});

// BG根据nid删除文章
export const bgDeleteArticle = nid => myajax(BgDeleteArticleUrl, {nid})

// BG获取文章分类列表
export const bgGetCategoryList = () => myajax(BgGetCategoryListUrl, {})

// BG添加文章
export const bgAddArticle = (formData) => myajax(BgAddArticleUrl, formData, 'POST')

// BG获取未读消息列表
export const bgGetURToDoList = () => myajax(BgURTodoListUrl, {})

// BG标记消息为已读
export const bgRDToDoList = id => myajax(BgRDTodoListUrl, {id})

// 确认用户权限
export const confirmUser = pid => myajax(ConfirmUserUrl, {pid})

// 获取用户实验室xx功能的状态
export const getUserFuncState = ({userid, funcname}) => myajax(GetUserFuncStateUrl, {userid, funcname})

// 为用户开通指定功能
export const addUserFunc = ({userid, funcname}) => myajax(AddUserFuncUrl, {userid, funcname})

// 获取LeetCode题目数据
export const getSubjectList = userid => myajax(GetSubjectListUrl, {userid})

// 改变LeetCode题目状态
export const changeSubjectState = ({userid, subjectid, state}) => myajax(ChangeSubjectStateUrl, {userid, subjectid, state})

// 改变xbclock配置
export const changeXbClockOptions = ({hardwareId, command, value}) => myajax(ChangeXbClockOptionsUrl, {hardwareId, command, value})

// 改变获取xbclockId
export const getXbClockId = userId => myajax(getXbClockIdUrl, {userId})


// 测试数据
export const reqRequestTest = () => myajax(Bgstatus, {});