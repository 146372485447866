import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { GithubOutlined, QqOutlined, WechatOutlined, MailOutlined, setTwoToneColor } from '@ant-design/icons'
import HomeHead from '../../components/home-head/home-head'
import Article from './ariticle/article'
// import ReactAplayer from 'react-aplayer'
// import ReactLive2d from 'react-live2d';
// import 'aplayer/dist/APlayer.min.css';
// import APlayer from 'aplayer';

import './home.css'
import LeftNav from '../../components/left-navMobel/left-nav'
import Index from '../index'
import State from './state/state'
import Labor from './labor/labor'
import Message from './message/message'
import About from './about/about'
import Read from './read/read'
import HomeFooter from '../../components/home-footer/home-footer'

setTwoToneColor('#ffffff');

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maskStatus: 'none',
            navStatus: '-50vw',
            categoryId: 0,
        }
    }
    componentDidMount=()=>{
        // const aplayerProps = {
        //     container: document.getElementById('aplayer'),
        //     autoplay: true,
        //     theme: '#FADFA3',
        //     preload: 'auto',
        //     lrcType: 3,
        //     mini: true,
        //     fixed: true,
        //     audio: [
        //         {
        //             name: '简单爱',
        //             artist: '周杰伦',
        //             url: 'https://link.hhtjim.com/kw/156522.mp3',
        //             cover: 'https://img4.kuwo.cn/star/albumcover/500/14/76/860786194.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: '说好的幸福呢',
        //             artist: '周杰伦',
        //             url: 'https://link.hhtjim.com/kw/440623.mp3',
        //             cover: 'https://img3.kuwo.cn/star/albumcover/500/72/44/3648126291.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: '夜曲',
        //             artist: '周杰伦',
        //             url: 'https://link.hhtjim.com/kw/118980.mp3',
        //             cover: 'https://img3.kuwo.cn/star/albumcover/500/32/88/1567952196.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: '明明就',
        //             artist: '周杰伦',
        //             url: 'https://link.hhtjim.com/kw/3197116.mp3',
        //             cover: 'https://img2.kuwo.cn/star/albumcover/500/59/22/1713105199.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: 'Go Solo',
        //             artist: 'Zwette&Tom Rosenthal',
        //             url: 'https://link.hhtjim.com/kw/81181591.mp3',
        //             cover: 'https://img3.kuwo.cn/star/albumcover/500/79/15/2601714281.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: 'Past Lives',
        //             artist: 'Martin Arteta',
        //             url: 'https://link.hhtjim.com/kw/150889299.mp3',
        //             cover: 'https://img3.kuwo.cn/star/albumcover/500/93/95/2311745002.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: '泡沫',
        //             artist: '多雷',
        //             url: 'https://link.hhtjim.com/kw/140681373.mp3',
        //             cover: 'https://img3.kuwo.cn/star/albumcover/500/86/92/238681446.jpg',
        //             theme: '#ebd0c2'
        //         },
        //         {
        //             name: '情书',
        //             artist: '克然Eli&Pary&苏克',
        //             url: 'https://link.hhtjim.com/kw/40900554.mp3',
        //             cover: 'https://img4.kuwo.cn/star/albumcover/500/59/26/2280216557.jpg',
        //             theme: '#ebd0c2'
        //         }
        //     ]
        // };
        // const ap = new APlayer(aplayerProps);
    }


    // 弹出侧边栏
    openNav = () => {
        this.setState({
            maskStatus: 'block',
            navStatus: '0'
        })
    }

    // 隐藏侧边栏
    closeNav = () => {
        this.setState({
            maskStatus: 'none',
            navStatus: '-50vw'
        })
    }

    changeCategoryId = (categoryId) => {
        // console.log(categoryId);
        this.setState({ categoryId });
        this.closeNav();
    }

    render() {
        const { maskStatus, navStatus, categoryId } = this.state;

        return (
            <HashRouter>
                <div className='home-root'>
                    {/* 定义网页遮罩 */}
                    <div className="home-mask" style={{ display: maskStatus }} onClick={this.closeNav}>
                    </div>

                    {/* 定义侧边栏容器 */}
                    <div className="home-nav" style={{ left: navStatus }}>
                        <LeftNav closeNav={this.closeNav} changeCategoryId={this.changeCategoryId} />
                    </div>

                    <div className='home-header'>
                        <HomeHead openNav={this.openNav} />
                    </div>
                    <div className='home-body'>
                        {/* <Article /> */}
                        {/* <div >
                            dsafdas
                            fdasfds
                        </div> */}
                        <Route path="/home/" exact component={Article} />
                        <Route path="/home/blog" component={Article} key={categoryId} />
                        <Route path="/home/state" component={State} />
                        <Route path="/home/labor" component={Labor} />
                        <Route path="/home/message" component={Message} />
                        <Route path="/home/about" component={About} />
                        <Route path='/home/read' component={Read} />
                    </div>
                    {/* <div id='aplayer' /> */}
                    
                    {/* <ReactLive2d
                        width={300}
                        height={500}
                        TouchBody={['啊呀，你的手在摸哪里嘛~', '哼，坏人', '再点我要报警啦！', '你点尼玛呢？']}
                    /> */}
                    <div className='home-footer'>
                        <HomeFooter />
                    </div>
                </div>
            </HashRouter>
        )
    }
}
