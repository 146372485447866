import React, { Component } from 'react'
import { Menu, message } from 'antd'
import { HomeOutlined, CopyOutlined, SmileOutlined, CommentOutlined, ExperimentOutlined, TeamOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';

import { getCategoryList } from '../../api/request';
import './left-nav.css'

const { SubMenu } = Menu;


export default class LeftNav extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categoryList:[]
        }
    }

    componentDidMount() {
        this.categoryInit();
    }
    

    categoryInit = async() => {
        const result = await getCategoryList();
        const categoryList = [];
        if(result){
            // console.log(result);
            result.forEach(element => {
                categoryList.push(
                    <Menu.Item key={ element['sort'] + 6 }>
                        <Link to= {'/home/blog?categoryid=' + element['nid']} onClick={ () => this.props.changeCategoryId(element['nid']) }>{ element['detail'] }</Link>
                    </Menu.Item>
                );
            });
            this.setState({ categoryList })
        }else{
            message.warning("分类列表获取失败！")
        }
    }

    managePage = () => {
        message.warning("你点尼玛呢！");
    }

    render() {
        const { categoryList } = this.state;
        return (
            <div className='left-nav-root'>
                <div className='left-nav-header'>
                    <div className='mobel-logo'>
                        <div className='logo-left'><p>西贝</p></div><div className='logo-right'><p>博客</p></div>
                    </div>
                </div>
                <div className='left-nav-body'>
                    <Menu mode="inline">
                        <Menu.Item key="1" icon={<HomeOutlined />}>
                            <Link to='/index'>首页</Link>
                        </Menu.Item>
                        <SubMenu key="sub1" icon={<CopyOutlined />} title="文章">
                            <Menu.Item key="6">
                                <Link to='/home/blog' onClick={ () => this.props.changeCategoryId(0) }>全部</Link>
                            </Menu.Item>
                            { categoryList }
                        </SubMenu>
                        <Menu.Item key="2" icon={<SmileOutlined />}>
                            <Link to='/home/state' onClick={ this.props.closeNav }>动态</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<CommentOutlined />}>
                            <Link to='/home/message' onClick={ this.props.closeNav }>留言板</Link>
                        </Menu.Item>
                        <Menu.Item key="4" icon={<ExperimentOutlined />}>
                            <Link to='/home/labor' onClick={ this.props.closeNav }>实验室</Link>
                        </Menu.Item>
                        <Menu.Item key="5" icon={<TeamOutlined />}>
                            <Link to='/home/about' onClick={ this.props.closeNav }>关于</Link>
                        </Menu.Item>
                        {/* <Menu.Item key="6" icon={<CalendarOutlined />} onClick={this.managePage}>
                        管理
                        </Menu.Item> */}
                    </Menu>
                </div>
            </div>
        )
    }
}
