import React from 'react'
import { Menu } from 'antd';
import {
    HomeOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    FormOutlined,
    SlackOutlined,
    MessageOutlined,
    RadarChartOutlined,
    FireOutlined,
    TeamOutlined,
    UserOutlined,
    UpCircleOutlined,
    LinkOutlined
  } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';


class LeftNav extends React.Component {

   state = {
    collapsed: false,
   }
    // 左边导航栏自适应宽度调整
    handleClientW = (width,num)=>{
        if(width < num){
        this.setState({
            collapsed:true
        })
        }else{
        this.setState({
            collapsed:false
        })
        }
    }

    handleResize = e => {
        let e_width = e.target.innerWidth;
        this.handleClientW(e_width,800);
        // console.log('浏览器窗口大小改变事件', e.target.innerWidth);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
        let clientW = document.documentElement.clientWidth;
        this.handleClientW(clientW,800);     
   }

    

    componentWillUnmount() {       
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    render() {
        // 如何url不带home的话自动加上
        const path = this.props.location.pathname === '/bgcontrol'? '/bgcontrol/home': this.props.location.pathname 
        // console.log('render()', path);
        return (
            <div className='LeftNav' style={{height: '95vh'}}>
                <Menu
                    mode="inline"
                    theme="dark"
                    selectedKeys={[path]}
                    inlineCollapsed={this.state.collapsed}
                    style={{backgroundColor: '#2F3447' }}
                >
                    <Menu.ItemGroup key='g1' title='概要'>
                        <Menu.Item key="/bgcontrol/home" icon={<HomeOutlined />}>
                            <Link to='/bgcontrol/home'>主页</Link>
                        </Menu.Item>
                        <Menu.Item key="/bgcontrol/basesetting" icon={<SettingOutlined />}>
                            <Link to='/bgcontrol/basesetting'>基本设置</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup key='g2' title='内容管理'>
                        <Menu.Item key='/bgcontrol/articlemanage' icon={<UnorderedListOutlined />}>
                        <Link to='/bgcontrol/articlemanage'>文章列表</Link>
                        </Menu.Item>
                        <Menu.Item key='/bgcontrol/addarticle' icon={<FormOutlined />}>
                        <Link to='/bgcontrol/addarticle'>添加文章</Link>
                        </Menu.Item>
                        <Menu.Item key="/bgcontrol/categorymanage" icon={<SlackOutlined />}>
                        <Link to='/bgcontrol/categorymanage'>分类管理</Link>
                        </Menu.Item>
                        <Menu.Item key="/bgcontrol/commentmanage" icon={<MessageOutlined />}>
                            <Link to='/bgcontrol/commentmanage'>评论管理</Link>
                        </Menu.Item>
                        <Menu.Item key='/bgcontrol/messagemanage' icon={<RadarChartOutlined />}>
                            <Link to='/bgcontrol/messagemanage'>留言板管理</Link>
                        </Menu.Item>
                        <Menu.Item key="/bgcontrol/statemanage" icon={<FireOutlined />}>
                            <Link to='/bgcontrol/statemanage'>动态管理</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup key='g3' title='用户管理'>
                        <Menu.Item key="9" icon={<TeamOutlined />}>
                            普通用户
                        </Menu.Item>
                        <Menu.Item key="10" icon={<UserOutlined />}>
                            管理员
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup key='g4' title='其他'>
                        <Menu.Item key="/bgcontrol/updatamanage" icon={<UpCircleOutlined />}>
                            <Link to='/bgcontrol/updatamanage'>更新日志管理</Link>
                        </Menu.Item>
                        <Menu.Item key="/bgcontrol/linkmanage" icon={<LinkOutlined />}>
                        <Link to='/bgcontrol/linkmanage'>友情链接管理</Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </Menu>
            </div>
        );
    }
}

// export default LeftNav;

export default withRouter(LeftNav)