import React, { useState, useEffect } from "react";
import { Select, Input, Button, Switch, Slider, message } from 'antd';
import { SketchPicker } from 'react-color'
import cookie from 'react-cookies'

import { changeXbClockOptions, getUserFuncState, addUserFunc, getXbClockId } from '../../../api/request'

import './xbclock.css'

const { Option } = Select;

// const hardwareId = 'jhx-001'

export function XbClock(props) {

    const [brightnessValue, setBrightnessValue] = useState(0);
    const [textColor, setTextColor] = useState('#FFFFFF');
    const [xbMessage, setXbMessage] = useState('');
    const [colorSelectFlag, setColorSelectFlag] = useState(false);
    const [xbclockState, setXbclockState] = useState(false);
    const [hardwareId, setHardwareId] = useState("");

    // 开机动画选择回调函数
    async function animationChange(value) {
        console.log(`selected ${value}`);
    }

    // 时间字体色彩动态变化勾选回调函数
    async function dateModeOnChange(checked) {
        console.log(`switch to ${checked}`);
        let flag = await optionChange({ hardwareId: hardwareId, command: 'dateMode', value: checked });
        if (flag == true) {
            message.success('设置成功');
        } else {
            message.error('设置失败');
        }
    }

    // 亮度更改回调函数
    function brightnessOnChage(value) {
        if (isNaN(value)) {
            return;
        }
        setBrightnessValue(parseInt(value * 100));
        optionChange({ hardwareId: hardwareId, command: 'brightness', value: value });
        // console.log(value);
    }

    // 发送文本颜色
    async function sendtextColor() {
        let rgba = textColor['rgb'];
        let flag = await optionChange({ hardwareId: hardwareId, command: 'color', value: rgba });
        if (flag == true) {
            message.success('设置成功');
        } else {
            message.error('设置失败');
        }
    }

    // 发送信息
    async function sendMessage() {
        if (xbMessage == '') {
            message.warning('发送内容不能为空');
            return;
        }
        let flag = await optionChange({ hardwareId: hardwareId, command: 'message', value: xbMessage });
        // console.log(flag);
        if (flag == true) {
            message.success('发送成功');
            setXbMessage('');
        } else {
            message.error('发送失败');
        }
    }

    async function optionChange(data) {
        let { hardwareId, command, value } = data;
        const result = await changeXbClockOptions({ hardwareId: hardwareId, command: command, value: value });
        console.log(result);
        if (result) {
            if (result['status'] == 'success') {
                return true;
            }
            return false;
        }
        return false;
    }

    // 发送文本input框内容更改回调函数
    function messageChange(e) {
        setXbMessage(e.target.value);
    }

    // 文本颜色更改回调函数
    function textColorOnChange(textColor) {
        setTextColor(textColor);
    }

    // 更改取色板显示状态
    function colorSelectOnchange() {
        setColorSelectFlag(!colorSelectFlag);
    }

    // 获取用户信息
    function getUserId() {
        let userMessage = cookie.load('userMessage');
        if (userMessage != null) {
            return userMessage.id;
        }
        return null;
    }

    useEffect(() => {
        const componentInit = async () => {
            let id = getUserId();
            if (id != null) {
                // 获取用户该功能的开启状态
                const result = await getUserFuncState({ userid: id, funcname: 'xbClock' });
                if (result.state == '1') {
                    setXbclockState(true);
                    const clockIdResult = await getXbClockId(id);
                    setHardwareId(clockIdResult.clockId);
                }
            }
        }
        componentInit();
    }, [])


    async function activeFunc(){
        let userId = getUserId();
        if(userId == null){
            message.warning("未登录，请登录后激活");
        }else{
            const result = await addUserFunc({userid: userId, funcname: 'xbClock'});
            console.log(result);
            if(result == 1){
                const clockIdResult = await getXbClockId(userId);
                message.success("激活成功");
                setHardwareId(clockIdResult.clockId);
                setXbclockState(true);
            }else{
                message.error("发生未知错误，激活失败");
            }
        }
    }


    return (
        <div>
            {
                xbclockState == false ?
                    <div className='lcrecordno-head'>
                        <h1>提示</h1>
                        <h4>请在完成硬件设备后激活本功能，项目地址：<a href="https://gitee.com/jia_hongxing/xbclock" target="black">xbClock项目</a>, 功能页面的硬件ID即为esp程序中55行的hardwareId，用户持此ID控制自己的设备。目前一个账户只支持绑定一个hardwareId。</h4>
                        <Button onClick={() => activeFunc()} style={{ backgroundColor: '#6bc30d', color: '#fff' }}>激活并使用</Button>
                    </div>
                    :
                    <div className='xbclock-root'>
                        <div className='xbclock-content xbclock-hardwareId'>
                            <h3>硬件ID: </h3>
                            <span>{hardwareId}</span>
                        </div>
                        <div className='xbclock-content xbclock-boot-animation'>
                            <h3>开机动画: </h3>
                            <Select defaultValue="cdr" style={{ width: 120 }} onChange={animationChange}>
                                <Option value="cdr">吃豆人os start</Option>
                                <Option value="disabled" disabled>
                                    这个选不了
                                </Option>
                            </Select>
                        </div>
                        <div className='xbclock-content xbclock-textColor'>
                            <h3>字体颜色: </h3>
                            {/* <Input placeholder="输入16进制色彩值"></Input> */}
                            {
                                colorSelectFlag == true ?
                                    <SketchPicker
                                        color={textColor}
                                        onChange={textColorOnChange}
                                    /> : ''
                            }
                            {colorSelectElement()}
                            <Button onClick={sendtextColor}>更改</Button>
                        </div>
                        <div className='xbclock-content xbclock-dateMode'>
                            <h3>时间色彩动态变化: </h3>
                            <Switch defaultChecked onChange={dateModeOnChange} />
                        </div>
                        <div className='xbclock-content xbclock-brightness'>
                            <h3>亮度: </h3>
                            <Slider
                                min={0.01}
                                max={1}
                                onChange={brightnessOnChage}
                                step={0.01}
                            />
                            <p className='xbclock-brightness-value'>
                                {brightnessValue + '%'}
                            </p>
                        </div>
                        <div className='xbclock-content xbclock-message'>
                            <h3>发送文本: </h3>
                            <Input placeholder="仅支持数字和英文字符" value={xbMessage} onChange={messageChange}></Input>
                            <Button onClick={sendMessage}>发送</Button>
                        </div>
                    </div>
            }
        </div>
    )

    function colorSelectElement() {
        return <div onClick={colorSelectOnchange}>
            <svg
                t="1636789996555"
                className="xbclock-icon"
                viewBox="0 0 1105 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2970"
                width="25"
                height="25">
                <path d="M960.8 227.6c-247.6-116.8-456.4 118.8-554.8 0-38.8-49.2 47.2-137.2 0-198.4-34.8-42.8-116.8-34.8-170 0C94.8 129.6 0.8 293.2 0.8 477.6 0.8 780.4 248.4 1024 555.6 1024c256 0 470.8-170 536.4-397.2 20-81.6 44.8-310.8-131.2-399.2z" fill="#35D0BA" p-id="2971">
                </path>
                <path d="M226 467.2m-82 0a82 82 0 1 0 164 0 82 82 0 1 0-164 0Z" fill="#FEFED5" p-id="2972">
                </path>
                <path d="M342.4 700.8m-82 0a82 82 0 1 0 164 0 82 82 0 1 0-164 0Z" fill="#FEFED5" p-id="2973">
                </path>
                <path d="M600.4 794.8m-82 0a82 82 0 1 0 164 0 82 82 0 1 0-164 0Z" fill="#FEFED5" p-id="2974">
                </path>
                <path d="M786.8 467.2m-122.8 0a122.8 122.8 0 1 0 245.6 0 122.8 122.8 0 1 0-245.6 0Z" fill="#FEFED5" p-id="2975">
                </path>
            </svg>
        </div>
    }
}