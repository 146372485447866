import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { MenuOutlined, QqOutlined } from '@ant-design/icons';
import { message, Avatar, Tooltip } from 'antd'
import cookie from 'react-cookies'

import './home-head.css'
// import logo from '../../image/home_logo.png'
import { qqLogin, getUserMessage } from '../../api/request'
import { baseURL } from '../../api/config'

const menuList = [
    {
        url: '/index',
        title: '首页'
    },
    {
        url: '/home/blog',
        title: '文章'
    },
    {
        url: '/home/state',
        title: '动态'
    },
    {
        url: '/home/message',
        title: '留言板'
    },
    {
        url: '/home/labor',
        title: '实验室'
    },
    {
        url: '/home/about',
        title: '关于'
    }
]

class HomeHead extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTitle : '',
            userMessage: {}
        }
    }

    componentDidMount() {
        this.userManage();
        let pathName = this.props.location.pathname;
        if(pathName == '/home/read'){
            pathName = '/home/blog';
        }
        this.setState({
            activeTitle: pathName
        })
        // console.log('head URL:' + this.state.activeTitle);
        // const cookieVal = {
        //     "id": 1,
        //     "nickName": "︶举个锤子彡",
        //     "sex": "男",
        //     "userLevel": 2,
        //     "openId": "65864915BA90CCC62F423F962A6CAD3B",
        //     "lastLogin": "2021-03-12T08:30:04.211+00:00",
        //     "city": "大马士革",
        //     "avatar": "http://thirdqq.qlogo.cn/g?b=oidb&k=uBfq8UfpusB6ywc49SNrtA&s=100&t=1591701487",
        //     "createTime": "2019-08-19T11:46:51.317+00:00"
        // }
        // cookie.save('userMessage', cookieVal);
        
    }

    
    userManage = async() => {
        let { userMessage } = this.state;
        userMessage = cookie.load('userMessage');
        // console.log(userMessage);
        // 判断cookie中是否已有用户信息
        if(userMessage){
            this.setState({userMessage})
            return;
        }
        else {
            let userOpenId = this.props.location.search;
            userOpenId = userOpenId.split('ken=')[1];
            if(userOpenId){
                const result = await getUserMessage(userOpenId);
                if(result){
                    // console.log(result);
                    let inFifteenMinutes = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000);//设置cookie有效期 30天
                    cookie.save('userMessage', result, { expires: inFifteenMinutes });
                    this.setState({userMessage: result})
                }
            }else{
                this.setState({userMessage: null})
            }
        }
    }

    changeMenu = (title) => {
        this.goToTop();
        this.setState({activeTitle: title});
    }    

    openNav = () => {
        this.props.openNav();
    }

    //  返回网页顶部
    goToTop(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }

    login = async() => {
        // console.log("click login ");
        // '127.0.0.1:3001' + this.props.location.pathname
        console.log('callbackUrl:' + 'https://www.jhxblog.cn/#' + this.props.location.pathname + this.props.location.search);
        const result = await qqLogin('https://www.jhxblog.cn/#' + this.props.location.pathname + this.props.location.search);
        // const result = await qqLogin('http://localhost/#' + this.props.location.pathname + this.props.location.search);
        if(result){
            window.location.href = result;
        }else{
            message.warning('跳转失败！');
        }
        
    }

    logOut = () => {
        cookie.remove('userMessage');
        this.setState({
            userMessage: null
        })
    }

    render() {
        const { activeTitle, userMessage } = this.state;
        return (
            <div className='home-head-root'>
                <div className='home-head-body'>
                    <div className='home-head-logo'>
                        <img src={baseURL + 'image/baseimage/home_logo.png'} />
                    </div>
                    <div className='home-head-menudiv'>
                        { 
                        //  动态渲染菜单栏
                            menuList.map((menu, index) => (
                                <Link to={menu.url} key={index}><li className={ activeTitle == menu.url?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu(menu.url)} >{menu.title}</li></Link>
                            ))
                        }
                        {/* <li className='home-head-menu' ><Link to='/index'>首页</Link></li>
                        <li className={ activeTitle == '文章'?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu('文章')} ><Link to='/home'>文章</Link></li>
                        <li className={ activeTitle == '动态'?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu('动态')} ><Link to='/state'>动态</Link></li>
                        <li className={ activeTitle == '留言板'?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu('留言板')} ><Link to='/message'>留言板</Link></li>
                        <li className={ activeTitle == '实验室'?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu('实验室')} ><Link to='/labor'>实验室</Link></li>
                        <li className={ activeTitle == '关于'?'home-head-active':'home-head-menu' } onClick={() => this.changeMenu('关于')} ><Link to='/about'>关于</Link></li> */}
                    </div>
                    <div className='home-head-menuModel'>
                        <MenuOutlined style={{fontSize: 'larger'}} onClick={this.props.openNav} />
                    </div>
                    <div className='home-head-logoModel'>
                        <img src={baseURL + 'image/baseimage/home_logo.png'} />
                    </div>
                    <div className='home-head-user home-head-userModel'>
                        {
                            
                            userMessage == null?<QqOutlined style={{ fontSize: '30px' }} onClick={this.login} /> : 
                            <Tooltip title="点击注销" color='lime' key='lime' placement='bottom'>
                                <Avatar size={40} src={userMessage['avatar']} onClick={this.logOut} />
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(HomeHead);
