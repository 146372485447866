import axios from 'axios'
import { message } from 'antd'
import qs from 'querystring'

axios.defaults.timeout = 10000;
const BASE_URL = 'http://127.0.0.1:8001/'


export default function ajax(url, data, type='GET') {
    // reqData = {}
    if(type === 'GET')
        return get(url, data)
    else
        return post(url, data)
    }


// 对get请求进行封装
function get(url, data={}){
    return new Promise((resolve, reject) => {
        let promise;
        // 1.执行异步ajax请求
        promise = axios.get(BASE_URL + url, {
            params: data
        })
        // 2.如果成功了，调用resolve(value)
        promise.then(response => {
            resolve(response.data)
        }).catch(error => {
            message.error('请求出错了：' + error.message)
            // reject('0')
        })
        // 3.如果失败了，不调用reject(reason)，而是提示异常信息   
    })
}

// 对post请求进行封装
function post(url, data){
    return new Promise((resolve, reject) => {
        let promise;
        // axios.defaults.headers.post['Content-Type'] = ContentType;
        // 1.执行异步ajax请求
        promise = axios.post(BASE_URL + url, data)
        // 2.如果成功了，调用resolve(value)
        promise.then(response => {
            resolve(response.data);
        }).catch(error => {
            message.error('请求出错了：' + error.message)
            reject(error)
        })
        // 3.如果失败了，不调用reject(reason)，而是提示异常信息   
    })
}
