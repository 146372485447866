import React from 'react'
import { Link, Route } from 'react-router-dom'
import { Row, Col } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'

import Home from './home/home';
import LeftNav from '../../components/left-nav/left-nav';
import BgHead from '../../components/bg-head/bg-head';
import BaseSetting from './baseSetting/baseSetting';
import CommentManage from './commentManage/commentManage';
import MessageManage from './messageManage/messageManage';
import StateManage from './stateManage/stateManage';
import CategoryManage from './categoryManage/categoryManage';
import UpdataManage from './updataManage/updataManage';
import './bgindex.css'
import LinkManage from './linkManage/linkManage';
import ArticleManage from './articleManage/articleManage';
import AddArticle from './articleManage/addArticle';
import Blogupload from './articleManage/blogupload'

class BgIndex extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            userMessage : {},
        }
    }

    componentDidMount() {
        
    }
    
    userManage = () => {
        let { userMessage } = this.state;
        userMessage = cookie.load('userMessage');
        // console.log(userMessage);
        // 判断cookie中是否已有用户信息
        if(userMessage){
            this.setState({userMessage})
            return;
        }
    }
    
    render() {
        return (
            <Row>
                <Col span={4}>
                    <div className='left-nav'>
                        <Link to='/' className='siteLogo' style={{height: 'calc(5vh - 16px)'}}>
                            <CoffeeOutlined style={{ fontSize: '2vw' }} />
                            <div style={{ fontSize: '2vw' }}>西贝博客</div>
                        </Link>
                        <hr style={{border:' 1px dashed rgba(235, 236, 237, 0.2)'}} />
                        <LeftNav />
                    </div>
                </Col>
                <Col span={20} style={{backgroundColor: '#F7F7F7'}}>
                    <div className='content'>
                        <div className='content-head'>
                            <BgHead />
                        </div>
                        <div className='content-body'>
                                <Route path="/" exact component={Home}/>
                                <Route path="/bgcontrol" exact component={Home}/>
                                <Route path="/bgcontrol/home" component={Home}/>
                                <Route path="/bgcontrol/basesetting" component={BaseSetting}/>
                                <Route path="/bgcontrol/commentmanage" component={CommentManage}/>
                                <Route path="/bgcontrol/messagemanage" component={MessageManage}/>
                                <Route path="/bgcontrol/statemanage" component={StateManage}/>
                                <Route path="/bgcontrol/categorymanage" component={CategoryManage} />
                                <Route path="/bgcontrol/updatamanage" component={UpdataManage} />
                                <Route path="/bgcontrol/linkmanage" component={LinkManage} />
                                <Route path="/bgcontrol/articlemanage" component={ArticleManage} />
                                <Route path="/bgcontrol/addarticle" component={AddArticle} />
                                <Route path="/bgcontrol/blogupload" component={Blogupload} />
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default BgIndex;