import React from 'react';
import BgIndex from './pages/background/bgindex';
import { HashRouter, withRouter, Switch, Route } from 'react-router-dom';
import './App.less';
import Index from './pages/index';
import Home from './pages/home/home';
import mdDemo from './pages/background/articleManage/mdDemo'


function App() {
  return (
    <HashRouter>
                <Switch>
                    <Route path='/' exact component={Index}></Route> 
                    <Route path='/index' component={Index}></Route>
                    <Route path='/home' component={Home}></Route>
                    <Route path='/bgcontrol' component={BgIndex}></Route>
                    {/* <Route path='/mddemo' component={mdDemo}></Route> */}
                </Switch>
    </HashRouter>
  );
}

export default App;
// export default withRouter(App);
