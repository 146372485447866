import React, { Component } from 'react'
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


export default class Blogupload extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            uploadFile: {}
        };
    }

    getStateData = () => {
        // console.log(this.state.uploadFile);
    }

    render() {
        // 图片上传控件配置
        const props = {
            showUploadList: true,
            action:'',
            onRemove: file => {
                this.setState({ uploadFile: {} });
            },
            
            // 上传动作前将图片赋值给this.state，返回false终止上传
            beforeUpload: file => {
                let { uploadFile } = this.state; 
                uploadFile = file;
                this.setState({ uploadFile: file });
                return false;
            },
          };

        return (
            <div>
                <Upload {...props}>
                    <Button  icon={<UploadOutlined />}>
                        点击上传
                    </Button>
                </Upload>
                <Button onClick={this.getStateData}>查看state数据</Button>
            </div>
        )
    }
}


