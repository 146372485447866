import { Statistic, Card, Space, Table, Tooltip } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { bgGetURToDoList, bgRDToDoList } from '../../../api/request'

import React from 'react'

import './home.css'

// 消息通知数据源
// let dataSource_message = [
//     {
//         key: '1',
//         message: 'xxx在留言板留言&https://www.baidu.com'
//     }
// ]

// 消息通知数据表格式
// const columns_message = [
//     {
//         title: '消息列表',
//         dataIndex: 'message',
//         render: text => {
//             // text = text.split('&');
//             // return <a href={text[1]} target='_blank'>{text[0]}</a>
//             return <a target='_blank'>{text}</a>
//         }
//     },
// ]

// 文章阅读量统计数据源
const dataSource_article = [

]

// 文章阅读量统计数据表格式
const columns_article = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '所属分类',
        dataIndex: 'category',
        key: 'category',
    },
    {
        title: '阅读量',
        dataIndex: 'readingNum',
        key: 'readingNum',
    },

]

// 最近评论统计数据源
const dataSource_remark = [
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    }, {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },
    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    },

    {
        key: '1',
        id: 1,
        userName: '张三',
        sex: '男',
        content: '这是一条评论！！！！！！！！！大萨达撒奥德赛阿达的撒dsa的撒dsa大撒旦哇群多我去去等得我前端去！'
    }

]

// 最近评论统计数据表格式
const columns_remark = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: 10,
    },
    {
        title: '用户名',
        dataIndex: 'userName',
        key: 'userName',
        width: 10,
    },
    {
        title: '性别',
        dataIndex: 'sex',
        key: 'sex',
        width: 10,
    },
    {
        title: '留言内容',
        dataIndex: 'content',
        key: "content",
        width: 30,
        onCell: () => {
            return {
                style: {
                    maxWidth: 150,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer'
                }
            }
        },
        render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
    },
]

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            // 消息通知数据源
            dataSource_message: []
         };
    }

    componentDidMount() {
        this.columnsInit();
        this.URToDoListInit();
    }

    columnsInit = () => {
        this.columns_message = [
            {
                title: '消息列表',
                dataIndex: 'message',
                render: text => {
                    text = text.split('&&');
                    return <a href={text[1]} target='_blank' onClick={() => this.RDToDoList(text[2])}>{text[0]}</a>
                }
            },
        ]
    }

    URToDoListInit = async() => {
        const { dataSource_message } = this.state;
        const result = await bgGetURToDoList();
        if(result){
            result.map((message, index) => {
                const temp = {
                    key: index,
                    message: message
                }
                dataSource_message.push(temp);
            }) 
            this.setState({dataSource_message});
        }else{
            console.log("获取未读消息列表失败！");
        }
        console.log(dataSource_message);
    }

    RDToDoList = (id) => {
        // console.log(id);
        const { dataSource_message } = this.state;
        const dataSource_message_new = [];
        dataSource_message.map((temp) => {
            if(temp.message.split('&&')[2] != id){
                dataSource_message_new.push(temp);
            }
        })
        this.setState({dataSource_message: dataSource_message_new});
        bgRDToDoList(id);
    }
    
    render() {
        const { dataSource_message } = this.state;
        return (
            <div className='bg-home-root'>
                <div className='bg-home-left'>
                    <div className='bg-home-message'>
                        <Card className='bg-home-card'>
                            <Statistic
                                title="网站访问量"
                                value={2499}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<ArrowUpOutlined />}
                                suffix="次"
                            />
                        </Card>
                        <Card className='bg-home-card'>
                            <Statistic
                                title="今日访问量"
                                value={2}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<ArrowUpOutlined />}
                                suffix="次"
                            />
                        </Card>
                        <Card className='bg-home-card'>
                            <Statistic
                                title="文章数量"
                                value={24}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="篇"
                            />
                        </Card>
                        <Card className='bg-home-card'>
                            <Statistic
                                title="用户数量"
                                value={30}
                                precision={0}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="个"
                            />
                        </Card>
                    </div>
                    <div className='bg-home-content'>
                        <Table
                            dataSource={dataSource_article}
                            columns={columns_article}
                            style={{ marginRight: '20px' }}
                            pagination={false}
                            title={() => '文章阅读量统计'}
                            className='bg-home-table'
                        />
                        <Table
                            dataSource={dataSource_remark}
                            columns={columns_remark}
                            style={{ marginRight: '20px' }}
                            // pagination={false}
                            title={() => '最近留言'}
                            pagination={{ pageSize: 7 }}//自定义每页显示的数据条数
                            className='bg-home-table'
                        />
                    </div>
                </div>
                <div className='bg-home-right'>
                    <div className='message-manage' key={dataSource_message.length}>
                        <Table
                            dataSource={dataSource_message}
                            columns={this.columns_message}
                            style={{ marginRight: '20px' }}
                            pagination={false}
                            className='bg-message-table'
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;