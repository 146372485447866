import React, { Component } from 'react'
import { Input, Button, Avatar, message } from 'antd';
import cookie from 'react-cookies'
import moment from 'moment';
import { ReactEmojiEditor, ReactEmojiShow } from 'react-emotor'

import timeFormat from '../../../api/timeFormat';
import { getMessageList, getMessagePageNum, addMessage } from '../../../api/request'
import './message.css'


export default class Message extends Component {
    constructor(props) {
        super(props)
        this.state = {
             messageList: [],
             page: 1,
             pageNum: 0,
             msVisiable: 'block',
             inputContent: '',
             remarkMessageId: '',
             remarkUserName: '',
             remarkVisiable: 'none'
        }
    }

    componentDidMount() {
        this.pageNumInit();
        this.messageInit(1);
    }

    inputChange = (content) => {
        // console.log(content);
        this.setState({ inputContent: content});
    }

    remark = (remarkMessageId, remarkUserName) => {
        // console.log(remarkMessageId, '  ', remarkUserName);
        // document.getElementById('messageInput').focus()
        this.setState({remarkMessageId, remarkUserName, remarkVisiable: 'inline'})
    }

    unRemark = () => {
        this.setState({
            remarkUserName: '',
            remarkVisiable: 'none'
        })
    }

    submitMessage = async() => {
        let { inputContent, remarkMessageId } = this.state;
        const userMessage = cookie.load('userMessage');
        // console.log(userMessage.openId);
        if(userMessage == null){
            message.warn({
                content:'你瞅瞅你登录了么？', 
                duration: 1
            });
        }else if(inputContent == ''){
            message.warn({
                content:'留言内容不能为空', 
                duration: 1
            });
        }else {
            const result = await addMessage(userMessage.openId, remarkMessageId, inputContent);
            let { messageList, remarkUserName } = this.state;
            if(result) {
                remarkMessageId == ''?message.success('留言成功！'):message.success('回复成功！');
                // 创建新留言对象，添加到messageList中
                let messageItem ={
                    "user": {
                        "id": userMessage.id,
                        "nickName": userMessage.nickName,
                        "sex": null,
                        "userLevel": userMessage.userLevel,
                        "openId": "",
                        "lastLogin": null,
                        "city": null,
                        "avatar": userMessage.avatar,
                        "createTime": ""
                    },
                    "reuser": remarkMessageId == ''?null:{nickName: remarkUserName},
                    "parentCommentId": null,
                    "createTime": moment().format('YYYY年MM月DD日 HH时mm分ss秒'),
                    "content": inputContent,
                    "child": [],
                    "nid": result
                }
                // console.log(messageItem);
                if(remarkMessageId == ''){
                    messageList.unshift(messageItem); // 在数值前面插入数据
                }else {
                    messageList.map(message => {
                        if(message.nid == remarkMessageId){
                            message.child.push(messageItem);
                        }
                    })
                }
                this.setState({
                    messageList,
                    inputContent: '',
                    remarkMessageId: '',
                    remarkUserName: '',
                    remarkVisiable: 'none'
                });
                document.getElementById('redit-textarea').innerHTML = ''
            }else {
                message.warning('留言失败！');
            }
        }
    }
    
    loadMore = () => {
        let { page } = this.state;
        page++;
        this.messageInit(page);
        this.setState({page});
    } 

    pageNumInit = async() => {
        const result = await getMessagePageNum();
        if(result){
            // console.log('pageNum:' + result);
            this.setState({
                pageNum: result
            })
        }else {
            message.warning("获取留言总页数失败")
        }
    }

    messageInit = async(page) => {
        const result = await getMessageList(page);
        if(result){
            if(result.size == 0){
                message.warning('全部加载完成');
            }
            // console.log('点击加载：' + result);
            let { messageList, msVisiable } = this.state; 
            msVisiable = 'none';
            result.forEach(message => {
                message['createTime'] = timeFormat(message['createTime']);
                message['child'].forEach(chiledMessage => {
                    chiledMessage['createTime'] = timeFormat(chiledMessage['createTime']);
                })
                // console.log(message);
                messageList.push(message);
            });
            // console.log(messageList);
            this.setState({ messageList, msVisiable })
        }else {
            message.warning("获取留言列表失败")
        }
    }
    
    render() {
        const { messageList, page, pageNum, msVisiable, remarkUserName, remarkVisiable } = this.state;
        return (
            <div className='message-root'>
                <div className='message-head'>
                    <div className='message-head-title'>
                        <h1>留言板</h1>
                        <p>海内存知己，天涯若比邻。</p>
                    </div>
                    <div className='message-head-content'>
                        {/* <TextArea showCount 
                            value={inputContent}
                            maxLength={100} 
                            placeholder="留下你的想法"
                            rows='5'
                            // disabled='false'
                            onChange={this.inputChange}
                            id= 'messageInput'
                        /> */}
                        <ReactEmojiEditor
                            placeholder = "留下你的想法"
                            onChange = {content => this.inputChange(content)}
                        />
                        <Button onClick={this.submitMessage} style={{marginTop: '15px'}}>提交</Button>
                        <span style={{display: remarkVisiable}}>{ '(回复：' + remarkUserName + ') '}</span>
                        <span style={{
                            display: remarkVisiable,
                            color: '#009688',
                            cursor: 'pointer'
                        }} onClick={this.unRemark}> 
                        取消回复
                        </span>
                    </div>
                </div>
                <div className='message-museScore' style={{display: msVisiable}}></div>
                <div className='message-body'>
                    <ul className='message-list'>
                        {   
                            messageList == null ? null:
                            messageList.map(message => (
                                <li className='message-item' key={message['nid']}>
                                    <div className='comment-parent'>
                                        <div className='comment-user-avatar'>
                                            <Avatar size={50} src={message['user']['avatar']} />
                                        </div>
                                        <div className='comment-body'>
                                            <div className='comment-user-name'>{message['user']['nickName']}</div>
                                            {/* <p className='comment-content'>{message['content']}</p> */}
                                            <div className='comment-content'><ReactEmojiShow content={message['content']}/></div>
                                            <span className='comment-time'>{message['createTime']}</span>
                                            <span className='comment-reply' onClick={() => this.remark(message['nid'], message['user']['nickName'])}>回复</span>
                                        </div>
                                    </div>
                                    {
                                        message['child']==null? null:message['child'].map(childMessage => (
                                            <div className='comment-child' key={childMessage['nid']}>
                                                <div className='comment-user-avatar'>
                                                    <Avatar size={40} src={childMessage['user']['avatar']}/>
                                                </div>
                                                <div className='comment-body'>
                                                    <div className='comment-user-name'>{childMessage['user']['nickName']}</div><span> 回复 </span><div className='comment-user-name'>{childMessage['reuser']['nickName']}</div><span>:</span>
                                                    {/* <p className='comment-content'>{childMessage['content']}</p> */}
                                                    <div className='comment-content'><ReactEmojiShow content={childMessage['content']}/></div>
                                                    <span className='comment-time'>{childMessage['createTime']}</span>
                                                    <span className='comment-reply' onClick={() => this.remark(message['nid'], childMessage['user']['nickName'])}>回复</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    
                                </li>
                            ))
                        }
                        {/* <li className='message-item'>
                            <div className='comment-parent'>
                                <div className='comment-user-avatar'>
                                    <Avatar size={50} src='http://thirdqq.qlogo.cn/g?b=oidb&k=uBfq8UfpusB6ywc49SNrtA&s=140&t=1554184180'/>
                                </div>
                                <div className='comment-body'>
                                    <div className='comment-user-name'>举个锤子</div>
                                    <p className='comment-content'>来换个友链，已添加贵站😋⁣ 博客名称：About刘磊 博客地址：https://abliulei.com 博客描述：读万卷书，行万里路 博客头像：https://s.gravatar.com/avatar/f2cd274ebde4d0855dc5836b000f9103?s=80</p>
                                    <span className='comment-time'>2020年07月24日 16:31</span>
                                    <span className='comment-reply'>回复</span>
                                </div>
                            </div>
                            <div className='comment-child'>
                                <div className='comment-user-avatar'>
                                    <Avatar size={40} src='http://thirdqq.qlogo.cn/g?b=oidb&k=uBfq8UfpusB6ywc49SNrtA&s=140&t=1554184180'/>
                                </div>
                                <div className='comment-body'>
                                    <div className='comment-user-name'>举个锤子</div><span> 回复 </span><div className='comment-user-name'>测试用户</div><span>:</span>
                                    <p className='comment-content'>来换个友链，已添加贵站😋⁣ 博客名称：About刘磊 博客地址：https://abliulei.com 博客描述：读万卷书，行万里路 博客头像：https://s.gravatar.com/avatar/f2cd274ebde4d0855dc5836b000f9103?s=80</p>
                                    <span className='comment-time'>2020年07月24日 16:31</span>
                                    <span className='comment-reply'>回复</span>
                                </div>
                            </div>
                        </li> */}
                    </ul>
                    <div className={ page < pageNum ? 'load-more' : 'load-more load-more-unvisiable' }> 
                        <div className='load-more-body' onClick={this.loadMore}>
                            <p>点击加载更多</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
