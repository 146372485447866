import React, { Component } from 'react'
import { Modal, Button, message } from 'antd';
import { Wkspider } from './wkspider';
import { LeetcodeRecord } from './leetcoderecord';
import cookie from 'react-cookies'

import './labor.css'
import { XbClock } from './xbclock';

const colorList = ['#CCFFCC', '#99CCCC', '#FFFFCC', '#CCFFFF', '#CCCCFF'];


export default class Labor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalVisiable: false,
            modalTitle: '',
            userMessage:''
        }
    }

    componentDidMount() {
        let userMessage = cookie.load('userMessage');
        if(userMessage){
            this.setState({userMessage})
        }
    }

    openXBclock = () => {
        const { userMessage } = this.state;
        if(userMessage != ''){
            if(userMessage['openId'] == '65864915BA90CCC62F423F962A6CAD3B'){
                this.setState({ modalTitle: 'XBclock管理工具', modalVisiable: true })
                return;
            }
        }
        message.warning('权限不足');
    }

    // countDown = () => {
    //     Modal.success({
    //         title: '友情提示',
    //         content: `页面重构中，如需使用百度文库提取器请移步gitee下载源码或桌面程序`,
    //         okText: '知道了'
    //     });
    // }

    delelopedSvg = () => {
        return <svg t="1634537801862" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6750" width="20" height="20"><path d="M887.904 298.208c-12.864-12.064-33.152-11.488-45.216 1.408L415.936 753.984l-233.12-229.696C170.208 511.872 149.952 512 137.536 524.608c-12.416 12.576-12.256 32.864 0.352 45.248l256.48 252.672c0.096 0.096 0.224 0.128 0.32 0.224 0.096 0.096 0.128 0.224 0.224 0.32 2.016 1.92 4.448 3.008 6.784 4.288 1.152 0.672 2.144 1.664 3.36 2.144 3.776 1.472 7.776 2.24 11.744 2.24 4.192 0 8.384-0.832 12.288-2.496 1.312-0.544 2.336-1.664 3.552-2.368 2.4-1.408 4.896-2.592 6.944-4.672 0.096-0.096 0.128-0.256 0.224-0.352 0.064-0.096 0.192-0.128 0.288-0.224l449.184-478.208C901.44 330.592 900.768 310.336 887.904 298.208z" p-id="6751" fill="#1afa29"></path></svg>
    }

    noDelelopSvg = () => {
        return <svg t="1634537451860" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6171" width="20" height="20"><path d="M557.312 513.248l265.28-263.904c12.544-12.48 12.608-32.704 0.128-45.248-12.512-12.576-32.704-12.608-45.248-0.128l-265.344 263.936-263.04-263.84C236.64 191.584 216.384 191.52 203.84 204 191.328 216.48 191.296 236.736 203.776 249.28l262.976 263.776L201.6 776.8c-12.544 12.48-12.608 32.704-0.128 45.248 6.24 6.272 14.464 9.44 22.688 9.44 8.16 0 16.32-3.104 22.56-9.312l265.216-263.808 265.44 266.24c6.24 6.272 14.432 9.408 22.656 9.408 8.192 0 16.352-3.136 22.592-9.344 12.512-12.48 12.544-32.704 0.064-45.248L557.312 513.248z" p-id="6172" fill="#d49b04"></path></svg>
    }

    willDelelopSvg = () => {
        return <svg t="1634537927461" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7380" width="20" height="20"><path d="M384 480 192 480c-52.928 0-96-43.072-96-96L96 192c0-52.928 43.072-96 96-96l192 0c52.928 0 96 43.072 96 96l0 192C480 436.928 436.928 480 384 480zM192 160C174.368 160 160 174.368 160 192l0 192c0 17.632 14.368 32 32 32l192 0c17.632 0 32-14.368 32-32L416 192c0-17.632-14.368-32-32-32L192 160z" p-id="7381" fill="#1296db"></path><path d="M384 928 192 928c-52.928 0-96-43.072-96-96l0-192c0-52.928 43.072-96 96-96l192 0c52.928 0 96 43.072 96 96l0 192C480 884.928 436.928 928 384 928zM192 608c-17.632 0-32 14.336-32 32l0 192c0 17.664 14.368 32 32 32l192 0c17.632 0 32-14.336 32-32l0-192c0-17.664-14.368-32-32-32L192 608z" p-id="7382" fill="#1296db"></path><path d="M832 928l-192 0c-52.928 0-96-43.072-96-96l0-64c0-52.928 43.072-96 96-96l192 0c52.928 0 96 43.072 96 96l0 64C928 884.928 884.928 928 832 928zM640 736c-17.664 0-32 14.336-32 32l0 64c0 17.664 14.336 32 32 32l192 0c17.664 0 32-14.336 32-32l0-64c0-17.664-14.336-32-32-32L640 736z" p-id="7383" fill="#1296db"></path><path d="M832 608l-192 0c-52.928 0-96-43.072-96-96L544 192c0-52.928 43.072-96 96-96l192 0c52.928 0 96 43.072 96 96l0 320C928 564.928 884.928 608 832 608zM640 160c-17.664 0-32 14.368-32 32l0 320c0 17.632 14.336 32 32 32l192 0c17.664 0 32-14.368 32-32L864 192c0-17.632-14.336-32-32-32L640 160z" p-id="7384" fill="#1296db"></path></svg>
    }

    changeModalTitle = () => {

    }

    render() {
        const { modalVisiable, modalTitle } = this.state;
        return (
            <div className='labor-root'>
                <div className='labor-title'>
                    <h1>实验室功能目录</h1>
                    <span><this.delelopedSvg />百度文库文章提取器</span>
                    <span><this.delelopedSvg />LeetCode刷题助手</span>
                    <span><this.delelopedSvg />XBclock管理工具</span>
                    <span><this.noDelelopSvg />ASCII码查找器</span>
                    <span><this.noDelelopSvg />密码记事本</span>
                    <span><this.willDelelopSvg />待开发工具 </span>
                    <h1>更新日志:</h1>
                    <p>2021年11月15日------XBclock管理工具</p>
                    <p>2021年10月18日------LeetCode刷题助手</p>
                    <p>2020年4月5日------百度文档提取器</p>
                    <br></br>
                    <h4>注: 本页面所有工具开源且仅供学习参考，不得用于任何形式的非法或盈利行为！</h4>
                </div>
                <Modal
                    title={modalTitle}
                    centered
                    visible={modalVisiable}
                    width={1000}
                    onCancel={() => this.setState({ modalVisiable: false })}
                    footer={
                        [] // 设置footer为空，去掉取消、确定默认按钮
                    }
                >
                    {
                        modalTitle == '百度文库文章提取器' ? <Wkspider /> : ''
                    }
                    {
                        modalTitle == '力扣刷题助手' ? <LeetcodeRecord /> : ''
                    }
                    {
                        modalTitle == 'XBclock管理工具' ? <XbClock /> : ''
                    }

                </Modal>
                <div className='labor-content'>
                    <div className='labor-item-father'>
                        <div className='labor-item' onClick={() => this.setState({ modalTitle: '百度文库文章提取器', modalVisiable: true })} >
                            <div className='labor-item-title'>
                                <h2>百度文库文章提取器</h2>
                            </div>
                            <div className='labor-item-description'>
                                <p>有效提取百度文库中的文章，提高写论文、报告效率，当前支持doc、txt文档</p>
                            </div>
                        </div>
                    </div>
                    <div className='labor-item-father'>
                        <div className='labor-item' onClick={() => this.setState({ modalTitle: '力扣刷题助手', modalVisiable: true })} >
                            <div className='labor-item-title'>
                                <h2>LeetCode刷题助手</h2>
                            </div>
                            <div className='labor-item-description'>
                                <p>提供各种类型题目推荐刷题顺序，记录题目完成状态，提高刷题效率</p>
                            </div>
                        </div>
                    </div>
                    <div className='labor-item-father'>
                        <div className='labor-item' onClick={() => this.setState({ modalTitle: 'XBclock管理工具', modalVisiable: true })} >
                            <div className='labor-item-title'>
                                <h2>XBclock管理工具</h2>
                            </div>
                            <div className='labor-item-description'>
                                <p>XBclock开源项目在线管理工具，向下发送时钟字体颜色、亮度、文字信息等控制信息</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='labor-item' style={{ backgroundColor: colorList[Math.ceil(Math.random() * 4)] }} onClick={() => this.setState({modalTitle: '测试功能2', modalVisiable: true})} >

                    </div>
                    <div className='labor-item' style={{ backgroundColor: colorList[Math.ceil(Math.random() * 4)] }} onClick={() => this.setState({modalTitle: '测试功能3', modalVisiable: true})} >

                    </div> */}
                </div>
            </div>
        )
    }
}
