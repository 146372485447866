import React, { Component } from 'react'
import { ReactEmojiShow } from 'react-emotor'

import { getStateList } from '../../../api/request'
import './state.css'

export default class State extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stateList: [],
            outStateNum: 0,
            visibleStateNum: 10,
            msVisiable: 'block'
        }
    }

    componentDidMount() {
        this.stateListInit();
        window.addEventListener('scroll', this.bindHandleScroll);
    }
    
    // 在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount(){
        window.removeEventListener('scroll', this.bindHandleScroll);
    }

    // 监听滚动事件，滚动到一定程度改变article-menu-category元素样式
    bindHandleScroll=(event)=>{
        // 滚动的高度
        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) 
                                    || window.pageYOffset
                                    || (event.srcElement ? event.srcElement.body.scrollTop : 0);
        // const elementHeight = document.getElementsByClassName('state-pole')[0].clientHeight; 
        const windowHeight = window.innerHeight;
        const elementHeight = windowHeight/5; 
        // console.log('可视框外的元素个数：', parseInt((scrollTop + 100)/elementHeight));
        // console.log('可视框中的元素个数：', parseInt(windowHeight/elementHeight));
        this.setState({
            outStateNum: parseInt((scrollTop + 100)/elementHeight),
            visibleStateNum: parseInt(windowHeight/elementHeight) + parseInt(scrollTop/elementHeight) + 1
        })
    }

    stateListInit = async() => {
        let result = await getStateList();
        if(result){
            result.map((state,i) => {
                state.createTime = this.timeTormat(state.createTime);
            })
        }
        // console.log(result);
        this.setState({
            stateList: result,
            msVisiable: 'none'
        })
    }

    // "2019-07-31T14:25:15.000+00:00"
    timeTormat = (oldTime) => {
        const newTime = oldTime.split('T')[0].replace(/-/g, '/');
        return newTime;
    }

    render() {
        const { stateList, outStateNum, visibleStateNum, msVisiable } = this.state;
        return (
            <div className='state-root'>
                <div className='state-museScore' style={{display: msVisiable}}></div>
                { 
                    stateList.map((state, i) => (
                        <div className='state-pole' key={i}>
                            <div className={ (i+1)>outStateNum && (i+1)<visibleStateNum? 'state-body state-body-visible':'state-body' }>
                                <time>{state.createTime}</time>
                                <div className='state-content'>
                                    <p><ReactEmojiShow content = {state.detail}/></p>
                                </div>
                            </div>
                        </div>
                    ))
                } 
            </div>
        )
    }
}
