import React from 'react'
import { Table, Tooltip, Space, message, Button, Modal } from 'antd'

import { bgDeleteComment, bgGetCommentList } from '../../../api/request';
import timeFormat from '../../../api/timeFormat';

class CommentManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            commentList: [],
            commentNid:0,    // 用于记录要删除评论的nid
            tableLoading: true,
            visible: false
         };
    }

    componentDidMount() {
        this.initColumns()
        this.getCommentList()

    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '评论ID',
                dataIndex: 'id',
                key: 'id',
                width: 10
            }, 
            {
                title: '用户名',
                dataIndex: 'userName',
                key: 'userName',
                width: 10
            }, 
            {
                title: '所属文章',
                dataIndex: 'articleId',
                key: 'articleId',
                width: 10,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
              },
              render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            }, 
            {
                title: '父评论ID',
                dataIndex: 'parentCommentId',
                key: 'parentCommentId',
                width: 10
            },
            {
                title: '评论时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            { 
                title: '评论内容', 
                dataIndex: 'content',
                key:"content",
                width: 40,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
              },
              render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            },  
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                  <Space size="middle">
                    <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                  </Space>
                ),
              },
        ]
    }

    // 改变对话框状态
    showDelete = (e) => {
        // this.state.commentNid = e.nid
        this.setState({
            visible: true,
            commentNid: e.nid
        })
    }

    // 删除评论数据
    deleteComment = async() => {
        // console.log(this.state.commentNid)
        const result = await bgDeleteComment(this.state.commentNid)
        if(result){
            let comments = this.state.commentList
            comments = comments.filter(item => item.nid !== this.state.commentNid)  // 删除评论列表中对应项
            message.success('删除成功!')
            this.setState({
                commentList: comments,
                visible: false
            })
        }else{
            message.error('评论删除失败！')
            this.setState({
                visible: false
            })
        }
    }

    // 获取评论列表
    getCommentList = async () => {
        const result = await bgGetCommentList()
        if(result){
            result.forEach(element => {
                element['createTime'] = timeFormat(element['createTime'])   // 格式化时间
                if(element['parentCommentId'] == null)
                    element['parentCommentId'] = '无';
                element['userName'] = element['user']['nickName'];
            });
            const commentList = result
            this.setState({
                commentList: commentList,
                tableLoading: false     // 表格加载动画flag设置为false
            })
        }else{
            message.error('获取评论列表失败！')
        }
    
    }
    

    render() {
        return (
            <div>
                <Table
                    columns = {this.columns}
                    dataSource = {this.state.commentList}
                    rowKey = 'nid'
                    loading = {this.state.tableLoading}
                    // rowClassName={(record, index) => {
                    //     let className = 'light-row';
                    //     if (index % 2 === 1) className = 'dark-row';
                    //     return className;
                    // }}
                />
                <Modal
                    visible = {this.state.visible}
                    title = '删除'
                    icon = 'ExclamationCircleOutlined'
                    okText = '删他娘的'
                    cancelText = '取消'
                    onOk = {this.deleteComment}

                >
                    <p>确定要删除这条评论吗？</p>
                </Modal>
            </div>
        );
    }
}

export default CommentManage;