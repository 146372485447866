import React, { Component } from 'react'
import { message } from 'antd';

import './about.css'
import { getUpdataList } from '../../../api/request'
import timeTormat from '../../../api/timeFormat'
import timeDf from '../../../api/timeDiffer'

export default class About extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
             updataList: [],
             timeDif: '',
             timerColor: '',
        }
    }

    componentDidMount() {
        this.updataInit();
        // this.demo();
        this.timerID = setInterval(this.siteRunTime, 1000);
    }

    //组件卸载取消倒计时
    componentWillUnmount() {
        clearInterval(this.timerID);
    }


    siteRunTime = () => {
        const timeDif = timeDf(new Date('2019/03/30 20:25').getTime());
        
        // console.log(timeDif);
        // 动态变换计时器字体颜色
        let R = Math.floor(Math.random()*255);
        let G = Math.floor(Math.random()*255);
        let B = Math.floor(Math.random()*255);
        // console.log('color: ' + '(' + R + ',' + G + ',' + B + ')');
        let timerColor = 'rgb(' + R + ',' + G + ',' + B + ')';
        this.setState({
            timeDif: timeDif,
            timerColor: timerColor
        });
    }
    
    updataInit = async() => {
        const result = await getUpdataList();
        if(result){
            result.map((updata,i) => {
                updata.createTime = timeTormat(updata.createTime);
                updata.createTime = updata.createTime.split(' ')[0];
            })
            this.setState({
                updataList: result
            })
        }else {
            message.warning('获取更新日志列表失败！');
        }
    }

    render() {
        const { updataList, timeDif, timerColor } = this.state;
        return (
            <div className='about-root'> 
                <div className='about-website'>
                    <h1>关于本站</h1>
                    <p>
                        本网站上线于2019年3月30日，用于记录与分享个人学习心得。
                    </p>
                    <span>
                        网站架构：
                    </span>
                    <ul>
                        <li>前 端 ：<code>React + AntD</code></li>
                        <li>后 端 ：<code>SpringBoot + Mybatis + MySQL5.8</code></li>
                        <li>服务器：<code>阿里云centos7</code></li>
                    </ul>
                    <p>网站已勉强运行<code style={{ color: timerColor }}>{timeDif}</code></p>
                </div>
                <div className='about-me'>
                    <h1>关于我</h1>
                    <p>
                            计算机科学与技术硕士在读，学术辣鸡一只<br />
                            国内北大清华等重点院校落榜生，著名电视剧、电影观众<br />
                            曾在优酷、爱奇艺、芒果TV等视频平台都充过会员<br />
                            擅长领域：网站前后端开发、嵌入式开发<br />
                            精通领域：吹牛逼、写bug<br />
                            个人网站非盈利性质，黑客大佬请绕行。
                    </p>
                    <span>
                        可以通过以下方式联系到我：
                    </span>
                    <ul>

                        <li>邮 箱 ：<a href="mailto:243340067@qq.com">243340067@qq.com</a></li>
                        <li>Gitee ：<a href="https://gitee.com/jia_hongxing" target="_blank">https://gitee.com/jia_hongxing</a></li>

                    </ul>
                </div>
                <div className='about-updata-log'>
                    <h1>更新日志</h1>
                    <ul className='updata-list'>
                        {
                            updataList.map((updataItem, i) => (
                                <li className='updata-item' key={i}>
                                    <div className="left-circle"></div>
                                    <div className="updata-content">
                                        <h3 className="layui-timeline-title">{updataItem['createTime']}</h3>
                                        <p>{updataItem['detail']}</p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className='about-notes'>
                    <h1>特别说明</h1>
                    <ul>
                        <li>本站所有内容仅代表个人观点，和任何组织或个人无关。</li>
                        <li>本站内容仅供学习交流，请勿用于任何形式商业行为。</li>
                        <li>对于本站原创文章，如需转载使用，请务必<code>注明</code>文章作者和来源.</li>
                        <li>本网站如无意中侵犯了某些组织或个人的知识产权，请来信或来电告之，本网站将立即予以删除。</li>
                    </ul>
                </div>
            </div>
        )
    }
}
