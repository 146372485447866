import React from 'react'
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

import './baseSetting.css'
let markdown = '# P01:课程介绍和环境搭建\n' +
    '[ **M** ] arkdown + E [ **ditor** ] = **Mditor**  \n' +
    '> Mditor 是一个简洁、易于集成、方便扩展、期望舒服的编写 markdown 的编辑器，仅此而已... \n\n' +
    '**这是加粗的文字**\n\n' +
    '*这是倾斜的文字*`\n\n' +
    '***这是斜体加粗的文字***\n\n' +
    '~~这是加删除线的文字~~ \n\n' +
    '\`console.log(111)\` \n\n' +
    '# p02:来个Hello World 初始Vue3.0\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n' +
    '***\n\n\n' +
    '# p03:Vue3.0基础知识讲解\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n\n' +
    '# p04:Vue3.0基础知识讲解\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n\n' +
    '#5 p05:Vue3.0基础知识讲解\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n\n' +
    '# p06:Vue3.0基础知识讲解\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n\n' +
    '# p07:Vue3.0基础知识讲解\n' +
    '> aaaaaaaaa\n' +
    '>> bbbbbbbbb\n' +
    '>>> cccccccccc\n\n' +
    '```var a=11; ```'

class BaseSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // console.log(moment().format('YYYY-MM-DD HH:mm:ss'));
        var temp = moment().format("YYYY-MM-DD").split("-")[2] <= 26
            ? moment().format("YYYY-MM").split("-")[1] == 1 ? moment().subtract(1, 'months').format("YYYY-MM") : moment().format("YYYY-MM")
            : moment().format("YYYY-MM")
        // console.log(temp);
        console.log(markdown);
    }


    render() {
        return (
            // <div className='base-setting'>
            //     {/* 基本设置页面 */}
            //     <div className='box1'>盒子1</div>
            //     <div className='box2'>盒子2</div>
            //     <ReactMarkdown
            //         source={markdown}
            //         escapeHtml={false}//html标签不解析保留文本
            //     />
            // </div>
            <ReactMarkdown
                children={markdown}
                escapeHtml={false}//html标签不解析保留文本
            />
            // <ReactMarkdown># Hello, *world*!</ReactMarkdown>
        );
    }
}

export default BaseSetting;