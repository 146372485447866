import * as React from 'react'
import * as ReactDOM from 'react-dom'
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
// import style manually
import 'react-markdown-editor-lite/lib/index.css';

import { UploadArticleImage } from '../../../api/request'

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

// Finish!
function handleEditorChange({ html, text }) {
    // console.log('handleEditorChange', html, text)
}

async function onCustomImageUpload(event) {
    console.log('onCustomImageUpload', event);
    const result = await UploadArticleImage();
    return new Promise((resolve, reject) => {
        // console.log(result);
        // const text = result['text'];
        // const url = result['url'];
        // console.log(text);
        // console.log(url);
        resolve(String(result['url']));
    });
};


export default class mdDemo extends React.Component {

    render() {
        return (
            <MdEditor
                style={{ height: "500px" }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleEditorChange}
                // imageUrl={this.state.url}
                onImageUpload={onCustomImageUpload}
            // onCustomImageUpload = {this.onCustomImageUpload}
            // insertMarkdown={'image',{target:this.state.text,imageUrl:this.state.url}}

            />
        )
    }
}
