import { Space, Table, message, Button, Modal, Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react'

import { bgDeleteLink, bgGetLinkList, bgAddLink } from '../../../api/request';
import timeFormat from '../../../api/timeFormat';
import './linkManage.css'

class LinkManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            linkList: [],
            tableLoading: true,
            deleteVisible: false,
            addVisible: false,
            linkNid: null,
            formKey: 1,
            webMessage: {
                webName: '',
                webLink:'',
                contact:''
            }
         };
    }

    componentDidMount() {
        this.initColumns()
        this.getLinkList()
    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '友链ID',
                dataIndex: 'nid',
                key: 'nid',
                width: 10
            }, 
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            { 
                title: '网站名', 
                dataIndex: 'webName',
                key:"webName",
                width: 40,
            },  
            { 
                title: '网站链接', 
                dataIndex: 'webLink',
                key:"webLink",
                width: 40,
            },  
            { 
                title: '站长联系方式', 
                dataIndex: 'contact',
                key:"contact",
                width: 40,
            }, 
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                    <Space size="middle">
                    <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                    </Space>
                ),
                },
        ]; 
    }

    // 改变对话框状态
    showDelete = (e) => {
        this.setState({
            deleteVisible: true,
            linkNid: e.nid
        })
    }

    // 取消删除动作
    hideDelete = () => {
        this.setState({
            linkNid: null,
            deleteVisible: false
        })
    }

    // 删除留言数据
    deleteLink = async() => {
        const result = await bgDeleteLink(this.state.linkNid)
        if(result){
            let link = this.state.linkList
            link = link.filter(item => item.nid !== this.state.linkNid)  // 删除评论列表中对应项
            message.success('删除成功!')
            this.setState({
                linkList: link,
                deleteVisible: false
            })
        }else{
            message.error('留言删除失败！')
            this.setState({
                deleteVisible: false
            })
        }
    }

    // 获取友情链接数据列表
    getLinkList = async () => {
        const result = await bgGetLinkList()
        if(result){
            result.forEach(element => element['createTime'] = timeFormat(element['createTime']))
            const linkList = result;
            // console.log(linkList);
            this.setState({
                linkList: linkList,
                tableLoading: false
            })
        }else{
            message.error('获取友链列表失败！')
        }
    }

    // 改变对话框状态
    changeAdd = () => {
        const addModalState = this.state.addVisible
        this.setState({
            addVisible: !addModalState
        })
    }

    // 添加友情链接
    addLink = async() => {
        const webMessage = this.state.webMessage
        let formKey = this.state.formKey + 1
        this.setState({formKey})
        if(webMessage.webName == '' || webMessage.webLink == ''){
            message.error('网站名与网站链接内容不能为空！')
            return
        }
        const result = await bgAddLink(webMessage)
        this.getLinkList()
        if(result){
            message.success('添加成功！')
            this.setState({
                webMessage: {
                    webName: '',
                    webLink:'',
                    contact:'',
                   
                },
                addVisible: false
            })
        }else{
            message.error('添加失败！')
        }
    }

    // 获取input框数据
    getInputData = (e) => {
        let webMessage = this.state.webMessage
        switch (e.target.name){
            case 'webName': webMessage.webName = e.target.value;break;
            case 'webLink': webMessage.webLink = e.target.value;break;
            case 'contact': webMessage.contact = e.target.value;break;
            default: break;
        }
        this.setState({webMessage})
        
        // console.log(e.target.name + ':' + e.target.value);
    }


    render() {
        return (
            <div>
                <Button id='add-link-btn' onClick={this.changeAdd}>添加友链</Button>
                <Table
                    columns = {this.columns}
                    dataSource = {this.state.linkList}
                    rowKey = 'nid'
                    loading = {this.state.tableLoading}
                />
                <Modal
                    visible = {this.state.deleteVisible}
                    title = '删除'
                    icon = 'ExclamationCircleOutlined'
                    okText = '删他娘的'
                    cancelText = '取消'
                    onOk = {this.deleteLink}
                    onCancel = { this.hideDelete }
                >
                    <p>确定要删除这条友情链接吗？</p>
                </Modal>
                <Modal
                    visible = {this.state.addVisible}
                    title = '添加友情链接'
                    okText = '确认添加'
                    cancelText = '取消'
                    onOk = {this.addLink}
                    onCancel = { this.changeAdd }
                >   
                    <Form key={this.state.formKey}>
                        <FormItem 
                            label="网站名称"
                            name="webName"
                            rules={[{ required: true, message: '网站名称不能为空' }]}
                        >
                            <Input name='webName' onChange={this.getInputData} />
                        </FormItem>
                        <FormItem 
                            label="网站链接"
                            name="webLink"
                            rules={[{ required: true, message: '网站链接不能为空' }]}
                        >
                            <Input name='webLink' onChange={this.getInputData}/>
                        </FormItem>
                        <FormItem 
                            label="站长联系方式"
                            name="contact"
                        >
                            <Input name='contact' onChange={this.getInputData}/>
                        </FormItem>
                    </Form>
                    
                </Modal>
            </div>
        );
    }
}

export default LinkManage;