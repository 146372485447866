import React, { Component } from 'react'
import { Pagination, message, Input, notification, Space, Spin } from 'antd';
import { TagsOutlined, EyeOutlined, CommentOutlined, SearchOutlined, QuestionCircleOutlined, SmileOutlined } from '@ant-design/icons'

// import articleview from '../../../image/article.png'
import './article.css'
import { getPagination, getArticleList, getHotArticleList, getLinkList, getCategoryList } from '../../../api/request'
import { Link, withRouter } from 'react-router-dom';
import { baseURL } from '../../../api/config'

// 友情链接气泡提示框
const openNotification = placement => {
    notification.info({
        message: `友链交换须知`,
        description:
        '1.技术博客优先-------------------------------- ' +
        '2.服务器稳定----------------------------------- ' +
        '3.内容合法、符合社会主义核心价值观------- ' +
        '★网站留言或联系QQ243340067',
        placement,
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
        
    });
};

class Article extends Component {

    constructor(props) {
        super(props)
        this.state = {
             articleTotalNum: 0,
             articleKey: 0,
             hotArticleNum: 8,
             pageSize: 8,
             articleList: [],
             hotArticleList: [],
             categoryId: 0,
             linkList: [],
             categoryList: [],
             scrollHandleFlag: false,
             scrollHandleStyle: {
                visibility: 'visible',
                size: 'scale(0)'
            },
            msVisiable: 'block',
            articleSpin: 1,
        }
        if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentDidMount() {
        const url = this.props.location.search;
        const categoryId = url == ''?0:url.split('=')[1];
        // console.log("categoryId: " + categoryId);
        this.paginationInit(categoryId);
        this.changePage(categoryId, 1, this.state.pageSize);
        this.hotArticleInit();
        this.linkListInit();
        this.categoryListInit();
        window.addEventListener('scroll', this.bindHandleScroll);
    }

    // 在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount(){
        window.removeEventListener('scroll', this.bindHandleScroll);
    }

    // 监听滚动事件，滚动到一定程度改变article-menu-category元素样式
    bindHandleScroll=(event)=>{
        let { scrollHandleFlag, scrollHandleStyle } = this.state;
        // 滚动的高度
        const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) 
                                    || window.pageYOffset
                                    || (event.srcElement ? event.srcElement.body.scrollTop : 0);
        // console.log(scrollTop);
        if(scrollTop>830 && scrollHandleFlag==false){
            scrollHandleStyle.visibility = 'hidden';
            scrollHandleStyle.size = 'scale(1)'
            scrollHandleFlag = true;
            this.setState({scrollHandleStyle: scrollHandleStyle, scrollHandleFlag: scrollHandleFlag});
        }else if(scrollTop<=830 && scrollHandleFlag==true){
            scrollHandleStyle.visibility = 'visible';
            scrollHandleStyle.size = 'scale(0)';
            scrollHandleFlag = false;  
            this.setState({scrollHandleStyle: scrollHandleStyle, scrollHandleFlag: scrollHandleFlag});
        }
    }

    // 分类列表数据初始化
    categoryListInit = async() => {
        const result = await getCategoryList();
        if(result) {
            // console.log("result:", result);
            this.setState({
                categoryList: result,
                msVisiable: 'none'
            })
        }else {
            message.warning("文章分类数据获取失败！");
        }
    }

    // 友情链接数据初始化
    linkListInit = async() => {
        const result = await getLinkList();
        if(result) {
            // console.log("result:", result);
            this.setState({
                linkList: result
            })
        }else {
            message.warning("友情链接数据获取失败！");
        }
    }

    // 热门文章数据初始化
    hotArticleInit = async() => {
        const result = await getHotArticleList(this.state.hotArticleNum);
        if(result) {
            // console.log("result:", result);
            this.setState({
                hotArticleList: result
            })
        }else {
            message.warning("热门文章数据获取失败！");
        }
    }

    // 分页组件初始化
    paginationInit = async(categoryId) => {
        const result = await getPagination(categoryId);
        let articleTotalNum = 0;
        if(result) {
            // console.log("result:", result);
            articleTotalNum = result;
         }else {
            // message.warning("文章分页数据获取失败！");
        }
        this.setState({
            articleTotalNum: result
        })
    }

    // 根据关键字查询文章
    searchByKeyword = () => {
        
    } 

    // 按文章分类获取文章列表
    changeCategoryId = (categoryId) => {
        // console.log("categoryId: " + categoryId);
        // this.setState({categoryId});
        this.paginationInit(categoryId);
        this.changePage(categoryId, 1, this.state.pageSize);
    }

    paginatiOnchangePage = (page, pageSize) => {
        this.changePage(this.state.categoryId, page, pageSize);
    }

    // 换页功能实现
    changePage = async(categoryId, page, pageSize) => {
        // console.log("page:", page);
        // console.log("pageSize:", pageSize);
        const result = await getArticleList(categoryId, page, pageSize);
        let { articleKey } = this.state;
        // console.log("按分类查找：", result);
        if(result) {
            result.map((article, i) => {
                article.createTime = article.createTime.split('T')[0];
                article.articleKey = articleKey;
                articleKey += 1;
            })
            // console.log("ArticleList:", result);
            this.setState({
                articleList: result,
                articleSpin: 0,
                articleKey, categoryId
            })
        }else {
            // message.warning("文章列表获取失败！");
        }
        this.goToTop();
    }

//  返回网页顶部
    goToTop(){
        let scrollToTop = window.setInterval(function() {
          let pos = window.pageYOffset;
          if ( pos > 0 ) {
              window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
          } else {
              window.clearInterval( scrollToTop );
          }
      }, 2);
    }
    
    render() {
        const { articleTotalNum, pageSize, categoryId, articleList, hotArticleList, linkList, categoryList, scrollHandleStyle, msVisiable, articleSpin } = this.state;
        return (
            <div className='article-root'>
                {/* 这个div是右侧搜索栏和分类列表， 为什么写两遍呢？因为某些不抗拒的因素 */}
                <div className='article-menu-sc category-move' style={{transform: scrollHandleStyle.size}}>
                    <div className='article-menu-search'>
                        <Input type="text" className="search-input" placeholder="输入关键字搜索" />
                        <span className='search-button'><SearchOutlined style={{ fontSize:'large' }} /></span>
                    </div>
                    <div className='article-menu-category'>
                        <ul className='category-list'>
                            <li onClick={() =>this.changeCategoryId(0)}><span>全部文章</span></li>
                            {
                                categoryList.map((category, i) => (
                                    <li onClick={() =>this.changeCategoryId(category.nid)} key={i}><span>{category.detail}</span></li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                {/* 回到顶部锚点 */}
                <div id="topAnchor"></div>
                <div className='article-museScore' style={{display: msVisiable}}></div>
                {
                    articleSpin == 1 ?
                    <div className='article-spin'>
                        <Space size="middle">
                            <Spin size="large" tip="Loading..."/>
                        </Space>
                    </div> :
                    <div className='article-list'>
                    {/* 文章信息，循环渲染 */}
                    {   
                        articleList.length != 0 ? articleList.map((article, i) => (
                            <div className='article-view' key={article.articleKey}>
                                <div className='article-view-head'>
                                    <h5 className="article-view-title">
                                        <span className="article-view-creative">【原创】</span>
                                        <Link to={"/home/read?articleid=" + article.nid} target="_blank">{article.title}</Link>
                                    </h5>
                                    <div className="article-view-time">
                                        <span className="day">{ article.createTime.split('-')[2] }</span>
                                        <span className="month">{ article.createTime.split('-')[1] }<span style={{ marginRight:'10px' }}>月</span></span>
                                        <span className="year">{ article.createTime.split('-')[0] }</span>
                                    </div>
                                </div>
                                <div className='article-view-content'>
                                    <img src={baseURL + 'image/articlephoto/' + (article.photo == ''?'article_defult.png':article.photo )}/>
                                    <p>{article.outline}...</p>
                                </div>
                                <div className='article-view-footer'>
                                    <div className="read-more">
                                        <Link to={'/home/read?articleid=' + article.nid} target="_blank">立即阅读</Link>
                                    </div>
                                    <div className='article-message'>
                                        <div className='article-tags'>
                                            <TagsOutlined rotate='-90' />
                                            {
                                                // 文章标签处理
                                                article.tags.split(',').map((tag, i) => (
                                                <a className="tag" key={i}>
                                                {tag}
                                                </a>))
                                            }
                                        </div>
                                        <div className='article-vc'>
                                            <EyeOutlined /> <span className='view-num' style={{ marginRight:'15px' }}>{ article.views }</span>
                                            <CommentOutlined /> <span className='commen-num'>{article.commentNum}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div className='article-zero'>未找到相关文章</div>
                    }
                    <div className='article-pagination' key={categoryId}>
                        <Pagination defaultCurrent={1} total={ articleTotalNum } 
                        pageSize={ pageSize } onChange={this.paginatiOnchangePage}
                        hideOnSinglePage={true}
                        />
                    </div>
                </div>
                }
                <div className='article-menu'>
                    <div className='article-menu-sc'>
                        <div className='article-menu-search'>
                            <Input type="text" className="search-input" placeholder="输入关键字搜索" onChange={this.searchByKeyword} />
                            <span className='search-button'><SearchOutlined style={{ fontSize:'large' }} /></span>
                        </div>
                        <div className='article-menu-category'>
                            <ul className='category-list'>
                                <li onClick={() =>this.changeCategoryId(0)}><span>全部文章</span></li>
                                {
                                    categoryList.map((category, i) => (
                                        <li onClick={() => this.changeCategoryId(category.nid)} key={i}><span>{category.detail}</span></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='article-menu-hot' style={{ visibility: scrollHandleStyle.visibility}}>
                        <h5>
                            热门文章
                        </h5>
                        <div className='hot-article'>
                            <ul>
                                {
                                    hotArticleList.map((article, i) => (
                                        <li key={i}><a href={ 'https://www.jhxblog.cn/#/home/read?articleid=' + article.nid } target='_Blank'>{article.title}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='article-menu-link' style={{ visibility: scrollHandleStyle.visibility}}>
                        <h5>
                            友情链接
                            <span className='link-message' onClick={() => openNotification('bottomRight')}><QuestionCircleOutlined /></span>
                        </h5>
                        <div className='friendly-link'>
                            <ul>
                                {
                                    linkList.map((link, i) => (
                                        <li key={i}><a href={link.webLink} target='_Blank'>{link.webName}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Article);
