import React from 'react'
import { Space, Table, message, Tooltip, Button, Modal } from 'antd';

import { bgGetArticleList, bgDeleteArticle } from '../../../api/request';
import timeFormat from '../../../api/timeFormat';

class ArticleManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            articleList: [],
            tableLoading: true,
            deleteVisible: false,
            articleNid: null,
         };
    }

    componentDidMount() {
        this.initColumns()
        this.getArticleList()
    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '文章ID',
                dataIndex: 'nid',
                key: 'nid',
                width: 10
            }, 
            {
                title: '文章标题',
                dataIndex: 'title',
                key: 'title',
                width: 10
            },
            {
                title: '所属分类',
                dataIndex: 'category',
                key: 'category',
                width: 10
            },
            {
                title: '创建(修改)时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            {
                title: '访问量',
                dataIndex: 'views',
                key: 'views',
                width: 10
            },
            { 
                title: '文章概要', 
                dataIndex: 'outline',
                key:"outline",
                width: 40,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            },  
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                    <Space size="middle">
                    {/* <Button onClick={{} = () :3001.showDelete(text)}>修改</Button> */}
                    修改
                    </Space>
                ),
            },
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                    <Space size="middle">
                        <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                    </Space>
                ),
            },
        ]; 
    }

    // 改变对话框状态
    showDelete = (e) => {
        // console.log("e.nid====", e.nid);
        this.setState({
            articleNid: e.nid,
            deleteVisible: true
        })
    }

    // 删除文章
    deleteArticle = async() => {
        // console.log(this.state.articleNid);
        if(this.state.articleNid == null){
            message.error('操作错误！')
            this.setState({
                deleteVisible: false
            })
        }
        const result = await bgDeleteArticle(this.state.articleNid)
        if(result){
            let articles = this.state.articleList
            articles = articles.filter(item => item.nid !== this.state.articleNid)
            message.success('删除成功!')
            this.setState({
                articleList: articles,
                deleteVisible: false
            })
        }
        else{
            message.error('文章删除失败！')
        }
    }

    // 取消删除动作
    hideDelete = () => {
        this.setState({
            articleNid: null,
            deleteVisible: false
        })
    }

    getArticleList = async() => {
        const result = await bgGetArticleList()
        if(result){
            result.forEach(element => element['createTime'] = timeFormat(element['createTime']))
            const articleList = result;
            // console.log(articleList);
            this.setState({
                articleList: articleList,
                tableLoading: false
            })
        }
        else{
            message.error('获取文章列表失败！');
        }
    }

    render() {
        return (
            <div>
                <Table
                    columns = {this.columns}
                    dataSource = {this.state.articleList}
                    rowKey = 'nid'
                    loading = {this.state.tableLoading}
                />
                <Modal
                    visible = {this.state.deleteVisible}
                    title = '删除'
                    icon = 'ExclamationCircleOutlined'
                    okText = '删他娘的'
                    cancelText = '取消'
                    onOk = {this.deleteArticle}
                    onCancel = { this.hideDelete }
                >
                    <p>确定要删除这篇文章吗？</p>
                </Modal>
            </div>
        );
    }
}

export default ArticleManage;