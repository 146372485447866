import React from 'react'
import ReactDOM from 'react-dom';
import { Table, Button, Modal, message, Tooltip, Space, Input } from 'antd'

import { bgAddState, bgDeleteState, bgGetStateList } from '../../../api/request'
import timeFormat from '../../../api/timeFormat';
import { ReactEmojiEditor } from 'react-emotor'

const { TextArea } = Input

class StateManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stateList: [],
            tableLoading: true,
            visible: false,
            stateNid: null,
            stateContent: ''
        };
    }

    componentDidMount() {
        this.initColumns()
        this.getStateList()
    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '动态ID',
                dataIndex: 'nid',
                key: 'nid',
                width: 10
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            {
                title: '动态内容',
                dataIndex: 'detail',
                key: "detail",
                width: 40,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                        }
                    }
                },
                render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            },
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                    <Space size="middle">
                        <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                    </Space>
                ),
            },
        ];

    }

    // 获取动态数据列表
    getStateList = async () => {
        const result = await bgGetStateList()
        // result.then((response) => {
        //     console.log("response:" + response);
        // }, (reason) => {
        //     console.log("reason:" + reason);
        // })
        // console.log('------------------------------');
        // console.log("updata result:" + Object.keys(result));
        // console.log("resultttt:" , result);
        if (result) {
            // console.log("resultttt:" , result);
            result.forEach(element => element['createTime'] = timeFormat(element['createTime']))
            const stateList = result;
            // console.log(stateList);
            this.setState({
                stateList: stateList,
                tableLoading: false
            })
        } else {
            message.error('获取动态列表失败！')
        }
    }

    // 改变对话框状态
    showDelete = (e) => {
        this.setState({
            stateNid: e.nid,
            visible: true
        })
    }

    // 取消删除动作
    hideDelete = () => {
        this.setState({
            stateNid: null,
            visible: false
        })
    }

    // 删除动态数据
    deleteState = async () => {
        if (this.state.stateNid == null) {
            message.error('操作错误！')
            this.setState({
                visible: false
            })
        }
        const result = await bgDeleteState(this.state.stateNid)
        if (result) {
            let states = this.state.stateList
            states = states.filter(item => item.nid !== this.state.stateNid)
            message.success('删除成功!')
            this.setState({
                stateList: states,
                visible: false
            })
        } else {
            message.error('动态删除失败！')
        }
    }

    // 获取新增动态的内容
    contentChange = (content) => {
        // console.log(content);
        this.setState({ stateContent: content });
    }

    // 添加动态
    addStates = async () => {
        // console.log(this.state.stateContent);
        const result = await bgAddState(this.state.stateContent)
        // console.log('result:' , result);
        if (result) {
            message.success('添加成功！')
            this.getStateList()
            this.setState({
                stateContent: ''
            })
        } else {
            message.error('添加失败！')
        }

    }

    render() {
        return (
            <div>
                <div className='addState'>
                    {/* <EmojiArea /> */}
                    {/* <TextArea showCount ref='textinput' maxLength={100} rows='4' value={this.state.stateContent} onChange = { this.contentChange } /> */}
                    <ReactEmojiEditor
                        placeholder="留下你的想法"
                        onChange={content => this.contentChange(content)}
                    />
                    <Button onClick={this.addStates}>添加动态</Button>
                </div>
                <Table
                    columns={this.columns}
                    dataSource={this.state.stateList}
                    rowKey='nid'
                    loading={this.state.tableLoading}
                />
                <Modal
                    visible={this.state.visible}
                    title='删除'
                    icon='ExclamationCircleOutlined'
                    okText='删他娘的'
                    cancelText='取消'
                    onOk={this.deleteState}
                    onCancel={this.hideDelete}

                >
                    <p>确定要删除这条动态吗？</p>
                </Modal>
            </div>
        );
    }
}

export default StateManage;