import React, { Component } from 'react'
import { Button, Image, Spin } from 'antd'
import './index.css'
import { NavLink } from 'react-router-dom'

import { baseURL } from '../../api/config'

const xbBgurl = 'https://xbblog.oss-cn-shanghai.aliyuncs.com/image/bg.jpg';





export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            maskVisiable: '',
            offsetX: 0,
            offsetY: 0,
            windowWidth: 0,
            windowHeight: 0
        }
    }

    componentDidMount() {
        let { windowHeight, windowWidth } = this.state;
        windowHeight = document.documentElement.clientHeight;
        windowWidth = document.documentElement.clientWidth;
        this.setState({ windowWidth, windowHeight })
        // 在componentDidMount，进行mousemove事件的绑定
        window.addEventListener('mousemove', this.moveMouse)
    }


    componentWillUnmount() {
        // 在componentWillUnmount，进行mousemove事件的注销
        window.removeEventListener('mousemove', this.moveMouse);
    }

    moveMouse = e => {
        let { offsetX, offsetY, windowWidth, windowHeight } = this.state;
        offsetX = parseInt(-(40 * e.clientX / windowWidth - 20)) + 'px';
        offsetY = parseInt(40 * e.clientY / windowHeight - 20) + 'px';
        this.setState({
            offsetX, offsetY
        })
    }

    bgOnload = () => {
        // alert("图片已加载")
        this.setState({
            maskVisiable: 'index-mask-out'
        })
    }

    enter = () => {
        this.props.history.push("/home/blog")
    }


    render() {
        const { maskVisiable, offsetX, offsetY } = this.state;
        const moveStyle = {
            transform: `translate(${offsetX}, ${offsetY})`
        };
        return (
            <div className='index-root'>
                <div className='index-bgimg'>
                    <Image src={xbBgurl} onLoad={this.bgOnload} style={moveStyle}></Image>
                </div>

                <div className={'index-mask ' + maskVisiable}>
                    <Spin size="large" />
                </div>
                <div className="index-site">
                       <h1 onClick={this.enter}>西贝博客</h1>
                    <div className='index-slogan'>
                        <p>向来心是看客心，奈何人是剧中人。</p>
                    </div>
                    {/* <div className='index-mask'></div> */}
                    {/* <Button type="primary" className='index-button'><NavLink to='/home/blog'>进入</NavLink></Button> */}
                </div>
            </div>
        )
    }
}