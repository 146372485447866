import React, { useState } from "react";

export function Wkspider(props) {

    const {
    } = props;

    const [content] = useState(''); 

    return (
        <div className='wkspider-root'>
            <h1>提示</h1>
            <p>由于工具使用python编写，网站更换技术栈后暂时无法实现网页端使用。继续使用工具请下载源码</p>
            <p>Gitee: <a href='https://gitee.com/jia_hongxing/wkspider' target='blank'>https://gitee.com/jia_hongxing/wkspider</a></p>
        </div>
    );

}




