import { Form, Input, Button, Select, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React from 'react'

import { bgGetCategoryList, bgAddArticle, UploadArticleImage } from '../../../api/request';
import './addArticle.css'
// markdown编辑器
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
// import style manually
import 'react-markdown-editor-lite/lib/index.css';

const { Option } = Select;
// const editormdConfig = {
//     width: '400px',
//     height: '300px',
//     path: path,
//     theme: theme,
//     previewTheme: previewTheme,
//     editorTheme: editorTheme,
//     markdown: config.markdown,
//     codeFold: codeFold,
//     syncScrolling: syncScrolling,
//     saveHTMLToTextarea: saveHTMLToTextarea,    // 保存 HTML 到 Textarea
//     searchReplace: searchReplace,
//     watch: true,                // 关闭实时预览
//     htmlDecode: htmlDecode,            // 开启 HTML 标签解析，为了安全性，默认不开启
//     toolbar: toolbar,             //关闭工具栏
//     previewCodeHighlight: previewCodeHighlight, // 关闭预览 HTML 的代码块高亮，默认开启
//     emoji: true,
//     taskList: taskList,
//     tocm: tocm,         // Using [TOCM]
//     tex: true,                   // 开启科学公式TeX语言支持，默认关闭
//     flowChart: flowChart,             // 开启流程图支持，默认关闭
//     sequenceDiagram: sequenceDiagram,       // 开启时序/序列图支持，默认关闭,
//     dialogLockScreen: true,   // 设置弹出层对话框不锁屏，全局通用，默认为true
//     dialogShowMask: true,     // 设置弹出层对话框显示透明遮罩层，全局通用，默认为true
//     dialogDraggable: true,    // 设置弹出层对话框不可拖动，全局通用，默认为true
//     dialogMaskOpacity: 0.1,    // 设置透明遮罩层的透明度，全局通用，默认值为0.1
//     dialogMaskBgColor: '#fff', // 设置透明遮罩层的背景颜色，全局通用，默认为#fff
//     imageUpload: imageUpload,
//     imageFormats: imageFormats,
//     imageUploadURL: imageUploadURL,
//     onload: function(){
//         //动态加载，重新创建编辑面板
//         editor.recreate();
//         // onload(editor, this);
//         this.setMarkdown(config.markdown);
//     }
// }

var editorContent = '123456';
// Markdown组件配置
const editorConfig = {
    markdown: localStorage.getItem("markdown"),
    width: '100%',
    height: '65vh',
    watch: true,       // 开启实时预览
    tex: true,           // 开启科学公式TeX语言支持，默认关闭
    flowChart: true,     // 开启流程图支持，默认关闭
    dialogLockScreen: true,   // 设置弹出层对话框不锁屏，全局通用，默认为true
    dialogShowMask: true,     // 设置弹出层对话框显示透明遮罩层，全局通用，默认为true
    dialogDraggable: true,    // 设置弹出层对话框不可拖动，全局通用，默认为true
    dialogMaskOpacity: 0.1,    // 设置透明遮罩层的透明度，全局通用，默认值为0.1
    dialogMaskBgColor: '#fff', // 设置透明遮罩层的背景颜色，全局通用，默认为#fff
    // emoji: true,  // 开启emoji表情
    theme: 'light',
    onchange: (editor) => {
        // console.log( Object.keys(editor),editor.getMarkdown());
        editorContent = editor.getMarkdown();
    }
}

const mdParser = new MarkdownIt(/* Markdown-it options */);


class AddArticle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],    // 文章分类列表
            articleMessage: {
                title: '',
                category: 0,
                author: 1,
                cover: {},
                tags: '',
                content: ''
            },
            coverName: '' // 上传图片后展示图片名
        };
    }

    componentDidMount() {
        // const editor = document.getElementsByClassName("editormd-markdown-textarea")[0];  // 获取editor元素
        // setInterval(() => {localStorage.setItem("markdown", editor.value)}, 5000)    // 将editor编辑器中的内容存入本地
        this.getCategoryList();
    }


    handleOk = async (e) => {//点击ok确认上传
        const { articleMessage } = this.state;
        let formData = new FormData();

        if (articleMessage['title'] == '' || articleMessage['category'] == 0 || articleMessage['content'] == '') {
            message.error("文章信息不完整！");
            return;
        }
        // console.log(articleMessage['title'] + "、" + articleMessage['category']);
        formData.append('title', articleMessage['title']);
        formData.append('category', articleMessage['category']);
        formData.append('author', articleMessage['author']);
        formData.append('cover', articleMessage['cover']);
        formData.append('tags', articleMessage['tags']);
        formData.append('content', articleMessage['content']);

        articleMessage['title'] = '';
        articleMessage['category'] = 0;
        articleMessage['author'] = 1;
        articleMessage['cover'] = {};
        articleMessage['tags'] = '';
        articleMessage['content'] = '';

        console.log(formData.get('title'));
        const result = await bgAddArticle(formData);
        if (result) {
            message.success('添加成功');
            this.setState({
                articleMessage: articleMessage,
                coverName: ''
            })

        } else {
            message.error('添加失败！');
        }
    };


    handleEditorChange = ({ html, text }) => {
        // console.log('handleEditorChange', html, text)
        const { articleMessage } = this.state;
        articleMessage['content'] = text;
        this.setState({articleMessage})
    }

    onCustomImageUpload = async (image) => {
        console.log(image);
        let imageData = new FormData();
        imageData.append('image', image);
        console.log(imageData);
        // console.log('onCustomImageUpload', event);
        const result = await UploadArticleImage(imageData);
        return new Promise((resolve, reject) => {
            // console.log(result);
            // const text = result['text'];
            // const url = result['url'];
            // console.log(text);
            // console.log(url);
            resolve(String(result['url']));
        });
    };

    // 获取文章分类列表
    getCategoryList = async () => {
        const result = await bgGetCategoryList();
        const categoryList = [];
        if (result) {
            // 将分类列表依次push到选择框的选项中
            result.forEach(element => {
                categoryList.push(<Option key={element['nid']} style={{ width: '100%' }}>{element['detail']}</Option>);
            });
            this.setState({ categoryList })
        } else {
            message.error('获取文章分类列表失败！')
        }
    }

    //选择框选择触发事件
    categoryHandleChanged = (value) => {
        let { articleMessage } = this.state;
        articleMessage.category = value;
        this.setState({ articleMessage })
        // console.log(this.state.articleMessage.category);
    }

    // input框组件onchange事件复用
    valueChange = e => {
        // const temp = e.target.value;
        // console.log(e.target.value);
        // console.log(type);
        // console.log(e.target.name);
        let { articleMessage } = this.state;
        switch (e.target.name) {
            case 'title': articleMessage['title'] = e.target.value; break;
            case 'tags': articleMessage['tags'] = e.target.value; break;
        }
        this.setState({ articleMessage });

    }

    render() {

        // 图片上传控件配置
        const props = {
            showUploadList: true,
            action: '',
            onRemove: file => {
                let { articleMessage } = this.state;
                articleMessage.cover = {};
                this.setState({ articleMessage, coverName: '' });
            },
            // 上传动作前将图片赋值给this.state，返回false终止上传
            beforeUpload: file => {
                let { articleMessage } = this.state;
                articleMessage.cover = file;
                this.setState({ articleMessage, coverName: file.name });
                return true;
            },
        };

        return (
            <div>
                <Form
                    className='article-form'
                    onFinish={this.handleOk}
                >
                    <Form.Item rules={[{ required: true }]} className='bg-addarticle-title'>
                        <h4>文章名</h4>
                        <Input value={this.state.articleMessage['title']} name="title" onChange={this.valueChange} />
                    </Form.Item>
                    <Form.Item name="category" className='bg-addarticle-category' >
                        <h4>文章分类</h4>
                        <Select
                            placeholder="请选择文章分类"
                            // onChange={this.onGenderChange}
                            allowClear
                            onChange={this.categoryHandleChanged}
                        >
                            {this.state.categoryList}
                        </Select>
                    </Form.Item>
                    <Form.Item label="文章作者" className='bg-addarticle-author'>
                        <Input name="author" disabled='true' value={this.state.articleMessage['author']} />
                    </Form.Item>
                    <Form.Item name="corver" label="文章封面" className='bg-addarticle-cover'>
                        <div key={Math.random()}>
                            <Upload {...props}>
                                <span>{this.state.coverName}  </span>
                                <Button icon={<UploadOutlined />}>
                                    点击上传
                                </Button>
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item label="标签" className='bg-addarticle-tags'>
                        <Input value={this.state.articleMessage['tags']} name="tags" onChange={this.valueChange} /><span>{' (以英文逗号为分隔符)'}</span>
                    </Form.Item>
                    <Form.Item className='bg-addarticle-content'>
                        {/* <Editor id='editormd' config = { editorConfig }/> */}
                        <MdEditor
                            style={{ height: "60vh" }}
                            renderHTML={(text) => mdParser.render(text)}
                            onChange={this.handleEditorChange}                            
                            onImageUpload={this.onCustomImageUpload}                
                        />
                    </Form.Item>
                    <Form.Item className='bg-addarticle-submitBT'>
                        <Button type="primary" htmlType="submit">提交</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default AddArticle;