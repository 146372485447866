import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Button, message, Avatar } from 'antd';
import cookie from 'react-cookies'
import moment from 'moment';
import marked from 'marked';
import hljs from 'highlight.js/lib/index';
import 'highlight.js/styles/github.css'

import { getArticle, getExtendArticle, getCommentList, addComment } from '../../../api/request'
import timeFormat from '../../../api/timeFormat'
import './read.css'
import randomWord from '../../../utils/randomWord'
import { ReactEmojiEditor, ReactEmojiShow } from 'react-emotor'
hljs.initHighlightingOnLoad()

const { TextArea } = Input;

// 文章内容
let contentSource = ''

marked.setOptions({ // marked 设置
    renderer: new marked.Renderer(),
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    highlight: function (code, lang) {
        if (lang && hljs.getLanguage(lang)) {
            return hljs.highlight(lang, code, true).value;
        } else {
            return hljs.highlightAuto(code).value;
        }
    }
})


class Read extends Component {

    constructor(props) {
        super(props)

        this.state = {
            articleMessage: {},
            articleId: 0,
            createTimeYear: '',
            createTimeMon: '',
            createTimeDay: '',
            lastArticle: null,
            nextArticle: null,
            articleDetailKey: '',
            commentList: [],
            inputContent: '',
            remarkCommentId: '',
            remarkUserName: '',
            remarkVisiable: 'none'
        }
    }

    componentDidMount() {
        // console.log('artile read page!');
        // console.log(this.props.location.search); 
        const url = this.props.location.search;
        const articleId = url.split('=')[1];
        // console.log(articleId);
        this.articleInit(articleId);
        this.extendArticleInit(articleId);
        this.commentInit(articleId);
    }

    articleInit = async (articleId) => {
        const result = await getArticle(articleId);
        let { articleDetailKey } = this.state;
        if (result) {
            let createTimeYear = result['createTime'].match(/(\S*)年/)[1];
            let createTimeMon = result['createTime'].match(/年(\S*)月/)[1];
            let createTimeDay = result['createTime'].match(/月(\S*)日/)[1];
            // articleDetailKey = "markdown" + Math.ceil(Math.random()*100);
            articleDetailKey = randomWord(true, 20, 32); // 生成一个长度为20-32的随机字符串
            // localStorage.setItem(articleDetailKey, result['content']);
            // sessionStorage.setItem(articleDetailKey, result['content']);
            contentSource = result['content'];
            contentSource = marked(contentSource);
            this.setState({
                articleMessage: result,
                articleDetailKey,
                articleId, createTimeYear, createTimeMon, createTimeDay
            })
        } else {
            message.warning('获取文章数据失败！');
        }
    }

    extendArticleInit = async (articleId) => {
        const result = await getExtendArticle(articleId);
        if (result) {
            // console.log(result);
            this.setState({
                lastArticle: result[0],
                nextArticle: result[1]
            })
        } else {
            message.warning('相关文章获取失败！');
        }
    }

    commentInit = async (articleId) => {
        const result = await getCommentList(articleId);
        let { commentList } = this.state;
        if (result) {
            result.forEach(comment => {
                comment['createTime'] = timeFormat(comment['createTime']);
                comment['child'].forEach(chiledComment => {
                    chiledComment['createTime'] = timeFormat(chiledComment['createTime']);
                })
                // console.log(message);
                commentList.push(comment);
            });
            // console.log(commentList);
            this.setState({ commentList });
        } else {
            message.warning('获取评论数据失败！');
        }
    }

    inputChange = (content) => {
        // console.log(content);
        this.setState({ inputContent: content });
    }

    submitComment = async () => {
        const { inputContent } = this.state;
        const userMessage = cookie.load('userMessage');
        // console.log(userMessage.openId);
        if (userMessage == null) {
            message.warn({
                content: '你瞅瞅你登录了么？',
                duration: 1
            });
        } else if (inputContent == '') {
            message.warn({
                content: '留言内容不能为空',
                duration: 1
            });
        } else {
            const { articleId, remarkCommentId, remarkUserName, remarkVisiable, commentList } = this.state;
            const result = await addComment(articleId, userMessage.openId, remarkCommentId, inputContent);
            if (result) {
                // console.log(result);
                remarkCommentId == '' ? message.success('评论成功！') : message.success('回复成功！');
                // 创建新评论对象，添加到commentList中
                let commentItem = {
                    "id": result,
                    "articleId": articleId,
                    "user": {
                        "id": userMessage.id,
                        "nickName": userMessage.nickName,
                        "sex": null,
                        "userLevel": userMessage.userLevel,
                        "openId": "",
                        "lastLogin": null,
                        "city": null,
                        "avatar": userMessage.avatar,
                        "createTime": ""
                    },
                    "reuser": remarkCommentId == '' ? null : { nickName: remarkUserName },
                    "parentCommentId": null,
                    "createTime": moment().format('YYYY年MM月DD日 HH时mm分ss秒'),
                    "content": inputContent,
                    "child": []
                }
                if (remarkCommentId == '') {
                    commentList.unshift(commentItem); // 在数值前面插入数据
                } else {
                    commentList.map(comment => {
                        if (comment.id == remarkCommentId) {
                            comment.child.push(commentItem);
                        }
                    })
                }
                this.setState({
                    commentList,
                    inputContent: '',
                    remarkCommentId: '',
                    remarkUserName: '',
                    remarkVisiable: 'none'
                });
            } else {

            }
        }
    }

    remark = (remarkCommentId, remarkUserName) => {
        // console.log(remarkCommentId, '  ', remarkUserName);
        // document.getElementById('commentInput').focus()
        this.setState({ remarkCommentId, remarkUserName, remarkVisiable: 'inline' })
    }

    unRemark = () => {
        this.setState({
            remarkUserName: '',
            remarkVisiable: 'none'
        })
    }


    render() {
        const { articleMessage, createTimeYear, createTimeMon, createTimeDay,
            lastArticle, nextArticle, commentList, articleDetailKey, remarkUserName, remarkVisiable, inputContent } = this.state;
        return (
            <div className='read-root'>
                <div className='read-content'>
                    <div className='read-content-title'>
                        <h4>{articleMessage['title']}</h4>
                        <p class="read-article-message">
                            <small>
                                作者：<a>{articleMessage['author']}</a>
                            </small>
                            <small>围观群众：<i>{articleMessage['views']}</i></small>
                            <br className='read-mobel-warp' />
                            <small className='read-mobel-category'>分类：<label>{articleMessage['category']}</label> </small>
                            <small>更新于：<label>{articleMessage['createTime']}</label> </small>
                        </p>
                    </div>
                    <div className='read-content-time'>
                        <span class="day">{createTimeDay}</span>
                        <span class="month">{createTimeMon}<span style={{ marginRight: '10px' }}>月</span></span>
                        <span class="year">{createTimeYear}</span>
                    </div>
                    <div className='read-article-body' key={articleDetailKey}>
                        {/* <Editor.EditorShow config={{
                            // markdown: sessionStorage.getItem(articleDetailKey)==null?'':sessionStorage.getItem(articleDetailKey)
                            markdown: contentSource
                        }} /> */}
                        <div dangerouslySetInnerHTML={{ __html: contentSource }} />
                    </div>
                </div>
                <div className='read-about'>
                    <div className='read-about-overflow'>
                        <p>
                            非特殊说明，本文版权归 <strong>举个锤子</strong> 所有，转载请注明出处.
                        </p>
                        <p>
                            本文标题：
                            <span>{articleMessage['title']}</span>
                        </p>
                        <p>
                            本文网址：
                            <a class="share-link" href={"https://www.jhxblog.cn/#/home/read?articleid=" + this.state.articleId} >{"https://www.jhxblog.cn/#/home/read?articleid=" + this.state.articleId}</a>
                        </p>
                    </div>

                </div>
                {/* <div className='read-extend'>
                    <a className='read-last' href={lastArticle == null ? 'javascript:void(0);' : 'https://jhxblog.cn/#/home/read?articleid=' + lastArticle.nid}>
                        <LeftOutlined style={{ fontSize: '20px', color: 'black' }} />
                        <div className='read-last-article'>
                            {
                                lastArticle == null ? <p>已经是第一篇文章了</p> :
                                    <div>
                                        <p>{lastArticle.title}</p>
                                        <div className='read-article-tags'>
                                            <span style={{ color: 'black' }}>in</span>
                                            {
                                                lastArticle.tags.split(',').map((tag) => <a>{tag}</a>)
                                            }
                                        </div>
                                    </div>
                            }

                        </div>
                    </a>
                    <div className='read-split'>

                    </div>
                    <a className='read-next' href={nextArticle == null ? 'javascript:void(0);' : 'https://jhxblog.cn/#/home/read?articleid=' + nextArticle.nid}>
                        <div className='read-next-article'>
                            {
                                nextArticle == null ? <p>已经是最后一篇文章了</p> :
                                    <div>
                                        <p>{nextArticle.title}</p>
                                        <div className='read-article-tags'>
                                            <span style={{ color: 'black' }}>in</span>
                                            {
                                                nextArticle.tags.split(',').map((tag) => <a>{tag}</a>)
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        <RightOutlined style={{ fontSize: '20px', color: 'black' }} />
                    </a>
                </div> */}
                <div className='read-share'>

                </div>
                <div className='read-comment'>
                    <div className='read-comment-head'>
                        <h3>发表评论</h3>
                        {/* <TextArea showCount
                            value={inputContent}
                            maxLength={100}
                            placeholder="点评一下"
                            rows='5'
                            // disabled='false'
                            onChange={this.inputChange}
                            id='commentInput'
                        /> */}
                        <ReactEmojiEditor
                            placeholder="点评一下"
                            onChange={content => this.inputChange(content)}
                        />
                        <Button onClick={this.submitComment} style={{marginTop: '20px'}}>提交</Button>
                        <span className='remark-message'>
                            <span style={{ display: remarkVisiable }}>{'(回复：' + remarkUserName + ') '}</span>
                            <span style={{
                                display: remarkVisiable,
                                color: '#009688',
                                cursor: 'pointer'
                            }} onClick={this.unRemark}>
                                取消回复
                            </span>
                        </span>
                    </div>
                    <div className='read-comment-body'>
                        <ul className='read-comment-list'>
                            {
                                commentList == null ? null :
                                    commentList.map(comment =>
                                        <li className='read-comment-item'>
                                            <div className='read-comment-parent'>
                                                <div className='read-user-avatar'>
                                                    <Avatar size={50} src={comment['user']['avatar']} />
                                                </div>
                                                <div className='read-comment-body'>
                                                    <div className='read-user-name'>{comment['user']['nickName']}</div>
                                                    {/* <p className='read-comment-content'>{comment['content']}</p> */}
                                                    <ReactEmojiShow content={comment['content']}/>
                                                    <span className='read-comment-time'>{comment['createTime']}</span>
                                                    <span className='read-comment-reply' onClick={() => this.remark(comment['id'], comment['user']['nickName'])}>回复</span>
                                                </div>
                                            </div>
                                            {
                                                comment['child'] == null ? null :
                                                    comment['child'].map((childComment, index) =>
                                                        <div className='read-comment-child' key={index}>
                                                            <div className='read-user-avatar'>
                                                                <Avatar size={50} src={childComment['user']['avatar']} />
                                                            </div>
                                                            <div className='read-comment-body'>
                                                                <div className='read-user-name'>{childComment['user']['nickName']}</div>
                                                                {/* <p className='read-comment-content'>{childComment['content']}</p> */}
                                                                <ReactEmojiShow content={childComment['content']}/>
                                                                <span className='read-comment-time'>{childComment['createTime']}</span>
                                                                <span className='read-comment-reply' onClick={() => this.remark(comment['id'], childComment['user']['nickName'])}>回复</span>
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        </li>
                                    )

                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Read);


