import React from 'react'
import { Table, message, Tooltip, Space, Button, Modal } from 'antd'

import timeFormat from '../../../api/timeFormat';
import { bgGetManageList, bgDeleteMessage } from '../../../api/request';

class MessageManage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            messageList: [],
            tableLoading: true,
            visible: false,
            messageNid: 0
         };
    }

    componentDidMount() {
        this.initColumns();
        this.getMessageList();
    }

    // 初始化数据源格式
    initColumns = () => {
        this.columns = [
            {
                title: '留言ID',
                dataIndex: 'nid',
                key: 'nid',
                width: 10
            }, 
            {
                title: '用户名',
                dataIndex: 'userName',
                key: 'userName',
                width: 10
            }, 
            {
                title: '父留言ID',
                dataIndex: 'parentCommentId',
                key: 'parentCommentId',
                width: 10
            },
            {
                title: '留言时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 10
            },
            { 
                title: '留言内容', 
                dataIndex: 'content',
                key:"content",
                width: 40,
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render: (text) => <Tooltip color='#2db7f5' placement="topLeft" title={text}>{text}</Tooltip>
            },  
            {
                title: '操作',
                key: 'action',
                width: 10,
                render: (text, record) => (
                  <Space size="middle">
                    <Button onClick={{} = () => this.showDelete(text)}>删除</Button>
                  </Space>
                ),
              },
        ];
        
    }

     // 改变对话框状态
     showDelete = (e) => {
        this.setState({
            visible: true,
            messageNid: e.nid
        })
    }

    // 删除留言数据
    deleteMessage = async() => {
        const result = await bgDeleteMessage(this.state.messageNid)
        if(result){
            let messages = this.state.messageList
            messages = messages.filter(item => item.nid !== this.state.messageNid)  // 删除评论列表中对应项
            message.success('删除成功!')
            this.setState({
                messageList: messages,
                visible: false
            })
        }else{
            message.error('留言删除失败！')
            this.setState({
                visible: false
            })
        }
    }

    // 获取留言板列表
    getMessageList = async() => {
        const result = await bgGetManageList()
        if(result){
            result.forEach(element => {
                element['createTime'] = timeFormat(element['createTime'])   // 格式化时间
                if(element['parentCommentId'] == null)
                    element['parentCommentId'] = '无';
                element['userName'] = element['user']['nickName'];
            });
            const messageList = result
            // console.log(messageList)
            this.setState({
                messageList: messageList,
                tableLoading: false
            })
        }else{
            message.error('获取留言列表失败！')
        }

    }


    render() {
        return (
            <div>
                <Table
                    columns = {this.columns}
                    dataSource = {this.state.messageList}
                    rowKey = 'nid'
                    loading = {this.state.tableLoading}
                />
                <Modal
                    visible = {this.state.visible}
                    title = '删除'
                    icon = 'ExclamationCircleOutlined'
                    okText = '删他娘的'
                    cancelText = '取消'
                    onOk = {this.deleteMessage}

                >
                    <p>确定要删除这条留言吗？</p>
                </Modal>
            </div>
        );
    }
}

export default MessageManage;
